<template>
  <svg
    viewBox="0 0 351 495"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="card-tema"
  >
    <g filter="url(#filter0_dd_2192_1993)">
      <g clip-path="url(#clip0_2192_1993)">
        <path
          d="M11.2305 21.5061C11.2305 15.9833 15.7076 11.5061 21.2305 11.5061L328.907 11.5061C334.429 11.5061 338.907 15.9833 338.907 21.5061V449.723L339.012 472.448C339.038 478.027 334.494 482.548 328.915 482.494L306.339 482.276H21.2304C15.7076 482.276 11.2305 477.799 11.2305 472.276L11.2305 21.5061Z"
          class="card-tema__background"
          fill="white"
        />
        <!-- <g clip-path="url(#clip1_2192_1993)" class="card-tema__arrow">
          <path
            d="M288.809 482.407L338.954 429.583L296.959 430.267C291.99 430.348 288.026 434.443 288.107 439.413L288.809 482.407Z"
            fill="#2B2B2B"
          />
          <path
            d="M288.377 439.409L289.067 481.744L338.317 429.862L296.964 430.537C292.143 430.615 288.298 434.587 288.377 439.409Z"
            stroke="#2B2B2B"
            stroke-width="0.538557"
          />
          <path
            d="M314.316 467.387C314.31 467.009 314.625 466.698 315.019 466.692L332.167 466.412C332.561 466.406 332.886 466.706 332.892 467.084C332.899 467.462 332.584 467.773 332.189 467.779L315.042 468.059C314.647 468.065 314.322 467.764 314.316 467.387Z"
            fill="#2B2B2B"
          />
          <path
            d="M325.86 461.245C326.135 460.973 326.587 460.966 326.87 461.228L332.675 466.604C332.959 466.866 332.966 467.299 332.691 467.571L327.065 473.133C326.79 473.405 326.337 473.412 326.054 473.149C325.771 472.887 325.764 472.454 326.038 472.183L331.168 467.112L325.876 462.211C325.592 461.949 325.585 461.516 325.86 461.245Z"
            fill="#2B2B2B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M326.639 461.456C326.488 461.316 326.245 461.32 326.098 461.465C325.951 461.611 325.955 461.843 326.107 461.983L330.574 466.12L331.657 466.103L326.639 461.456ZM333.161 466.75C333.108 466.613 333.024 466.484 332.906 466.376L327.101 461C326.686 460.616 326.024 460.626 325.622 461.024C325.22 461.422 325.23 462.055 325.645 462.44L329.636 466.136L315.014 466.374C314.436 466.384 313.975 466.839 313.984 467.392C313.993 467.945 314.469 468.386 315.047 468.376L329.669 468.138L325.8 471.962C325.398 472.36 325.408 472.993 325.823 473.378C326.238 473.762 326.901 473.751 327.303 473.354L332.929 467.791C333.043 467.679 333.124 467.548 333.172 467.408C333.197 467.335 333.214 467.258 333.221 467.178C333.226 467.112 333.225 467.045 333.217 466.98C333.208 466.9 333.189 466.823 333.161 466.75ZM331.689 468.105L330.607 468.123L326.277 472.403C326.129 472.549 326.133 472.781 326.285 472.921C326.437 473.062 326.679 473.058 326.826 472.912L331.689 468.105ZM331.26 467.477L332.184 467.462C332.395 467.458 332.564 467.292 332.561 467.089C332.557 466.887 332.383 466.726 332.172 466.729L331.248 466.744L331.398 466.884C331.462 466.942 331.498 467.022 331.499 467.107C331.501 467.191 331.467 467.272 331.406 467.333L331.26 467.477ZM330.31 466.76L315.025 467.009C314.813 467.012 314.645 467.179 314.648 467.381C314.651 467.584 314.825 467.745 315.037 467.741L330.322 467.492L330.698 467.12L330.31 466.76Z"
            fill="#2B2B2B"
          />
        </g> -->
        <g clip-path="url(#clip2_2192_1993)">
          <path
            d="M151.729 188.506L152.687 258.035L231.251 257.305L211.455 173.054C185.788 175.904 166.037 179.655 151.729 188.501V188.506Z"
            fill="white"
          />
          <path
            d="M231.25 257.305L256.935 257.066L253.352 148.04C253.352 148.04 248.574 169.213 217.504 172.409C215.45 172.621 213.436 172.838 211.454 173.054L231.25 257.305Z"
            fill="#66C337"
          />
          <path
            d="M151.729 188.506C135.74 198.394 126.548 214.643 123.505 243.125V258.306L152.687 258.036L151.729 188.506Z"
            fill="#E21C00"
          />
          <path
            d="M157.159 81.5215L221.686 54.5545L226.464 19.1967L216.907 22.195V30.5814L206.749 32.98L206.151 25.1889L195.997 29.9862L195.399 37.7729L188.827 40.7712L187.631 34.779L181.06 38.3725L179.864 43.1699L172.694 46.1637L170.901 40.7712L164.329 44.9644V49.7617L157.159 52.7555L156.562 47.363L149.396 50.9565L157.159 81.5215Z"
            fill="#E8B000"
          />
          <path
            d="M221.087 64.1446C221.087 64.1446 213.32 80.9263 206.15 85.7191C206.15 85.7191 209.733 100.702 190.018 98.9028C190.018 98.9028 186.435 92.9106 182.848 94.1099C179.261 95.3093 176.874 108.493 181.055 112.086L174.483 124.67C174.483 124.67 182.848 135.455 178.663 142.047C174.478 148.639 164.324 156.43 160.742 146.24C160.742 146.24 139.831 142.647 144.609 126.465C144.609 126.465 127.285 119.873 131.465 106.09C135.646 92.3064 136.841 84.5153 157.154 81.5214L221.681 54.5545L221.083 64.1446H221.087Z"
            fill="#E28800"
          />
          <path
            d="M213.123 138.454L186.836 135.658L192.81 149.64C192.81 149.64 197.988 138.855 213.123 138.454Z"
            fill="#E8B000"
          />
          <path
            d="M291.853 146.709C291.853 146.709 292.253 150.303 295.305 151.637C295.305 151.637 295.97 149.906 295.57 149.108C295.17 148.31 294.374 147.512 294.374 147.512L291.853 146.714V146.709Z"
            fill="#E8B000"
          />
          <path
            d="M292.119 155.497L291.588 158.162L295.04 155.898L296.236 156.696L295.571 158.428L297.562 158.292L297.297 165.218L294.245 165.083L295.175 161.354L291.458 161.755L291.323 163.22L293.184 163.085C293.184 163.085 291.988 164.551 293.049 165.349C294.11 166.147 297.297 168.811 297.297 168.811L296.897 171.873L289.327 171.075V157.756L292.114 155.492L292.119 155.497Z"
            fill="#E8B000"
          />
          <path
            d="M302.209 150.037L303.67 155.096C303.67 155.096 306.192 154.564 305.926 152.566C305.661 150.569 303.8 150.037 302.209 150.037Z"
            fill="#E8B000"
          />
          <path
            d="M306.987 156.164C306.987 156.164 308.979 156.43 308.048 158.162C307.118 159.893 305.657 159.361 305.657 159.361L303.531 157.896C303.531 157.896 302.735 155.497 303.666 155.1C304.596 154.704 307.383 155.366 306.983 156.164H306.987Z"
            fill="#E8B000"
          />
          <path
            d="M299.023 170.813L304.997 171.079L306.457 178.136L296.896 183.997L297.162 175.476L299.023 170.813Z"
            fill="#E8B000"
          />
          <path
            d="M310.57 170.949L312.296 169.749L314.688 174.141L309.379 176.941L310.57 170.949Z"
            fill="#E8B000"
          />
          <path
            d="M316.944 148.175L315.483 151.502C315.483 151.502 316.279 150.303 316.679 151.236C317.079 152.169 315.749 152.832 315.749 152.832L316.809 155.362L318.936 150.835C318.936 150.835 318.405 148.571 316.944 148.17V148.175Z"
            fill="#E8B000"
          />
          <path
            d="M310.04 161.972L312.962 167.481C312.962 167.481 313.757 170.809 315.484 170.412C317.21 170.015 317.21 167.883 316.149 167.617L314.688 167.351L313.627 166.553C313.627 166.553 314.953 164.956 313.627 163.622L312.301 162.292L310.045 161.976L310.04 161.972Z"
            fill="#E8B000"
          />
          <path
            d="M142.227 262.301L138.244 184.394L194.005 168.013L238.614 221.947L257.731 189.187L272.07 214.756C272.07 214.756 275.653 261.102 274.462 261.102C273.271 261.102 142.227 262.301 142.227 262.301Z"
            fill="white"
            class="card-tema__background"
          />
          <path
            d="M167.987 152.156C162.966 152.156 159.568 149.784 158.439 145.564C154.111 145.871 149.575 143.905 145.503 139.942C141.978 136.511 140.729 131.808 141.911 126.582C134.458 123.679 130.008 117.515 129.635 109.472C128.997 95.7739 136.643 88.3389 143.17 84.5109C150.114 80.4395 157.113 79.5558 157.41 79.5197L157.788 82.6533C157.657 82.6714 151.031 83.5325 144.675 87.2838C136.328 92.2119 132.323 99.6289 132.777 109.323C133.236 119.111 140.185 122.768 144.284 124.075L145.772 124.549L145.314 126.041C144.311 129.31 143.907 133.981 147.701 137.674C151.503 141.376 155.657 143.003 159.397 142.255L161.051 141.926L161.262 143.603C161.725 147.246 164.063 149.072 168.203 148.991C171.421 148.937 174.19 147.03 175.799 143.756C177.768 139.748 178.271 132.633 173.196 125.775L175.723 123.895C180.595 130.482 181.733 138.828 178.622 145.154C176.473 149.527 172.693 152.079 168.256 152.152C168.171 152.152 168.081 152.152 167.996 152.152L167.987 152.156ZM157.783 82.6533L157.594 81.0888L157.783 82.6533Z"
            fill="#1D1D1B"
          />
          <path
            d="M238.63 266.512L235.803 265.128L248.483 239.022L194.345 170.647C189.288 164.258 188.448 155.777 192.156 148.517C195.864 141.258 203.214 136.98 211.341 137.372L230.49 138.283L244.2 92.9062L236.216 92.6988C231.011 92.5636 226.228 90.3452 222.758 86.4497C219.284 82.5586 217.616 77.5448 218.056 72.3417L223.244 21.5098L217.373 23.2772L216.825 30.7798L204.401 34.9324L204.432 28.9447L197.339 31.9296L197.964 38.0209L187.122 42.0653L186.353 36.8757L180.981 39.085L181.71 43.8913L170.764 47.8861L169.838 43.8643L164.696 46.0781L165.653 50.393L155.247 54.9739L154.15 50.5688L152.101 51.4841L159.055 81.4043L155.989 82.1212L148.446 49.6626L156.295 46.1638L157.396 50.5824L162.021 48.5444L161.059 44.207L172.059 39.4727L173.034 43.6975L178.217 41.8083L177.502 37.1101L188.875 32.4255L189.666 37.7504L194.588 35.9153L193.977 29.9232L207.61 24.188L207.574 30.5454L213.845 28.4488L214.397 20.8785L226.844 17.1272L221.18 72.6348C220.816 76.9361 222.205 81.1068 225.096 84.3441C227.986 87.5814 231.96 89.43 236.293 89.5427L248.402 89.8583L232.787 141.551L211.188 140.528C204.315 140.199 198.094 143.815 194.952 149.96C191.814 156.106 192.525 163.279 196.804 168.69L252.178 238.621L238.63 266.517V266.512Z"
            fill="#1D1D1B"
          />
          <path
            d="M215.439 22.0348L213.488 56.1688L214.835 56.2462L216.785 22.1122L215.439 22.0348Z"
            fill="#1D1D1B"
          />
          <path
            d="M204.599 27.0509L204.451 59.6355L205.8 59.6416L205.947 27.057L204.599 27.0509Z"
            fill="#1D1D1B"
          />
          <path
            d="M164.016 44.9969L162.699 45.2885L169.154 74.6153L170.471 74.3236L164.016 44.9969Z"
            fill="#1D1D1B"
          />
          <path
            d="M155.38 48.6946L154.071 49.0188L161.403 78.8021L162.712 78.4778L155.38 48.6946Z"
            fill="#1D1D1B"
          />
          <path
            d="M139.457 259.298L136.809 201.157C135.978 183.677 145.696 167.675 161.563 160.394L164.067 159.244C170.468 156.304 175.053 150.673 176.644 143.797C178.235 136.921 176.59 129.838 172.131 124.368L174.568 122.371C179.647 128.602 181.521 136.668 179.71 144.509C177.898 152.35 172.671 158.766 165.375 162.116L162.871 163.266C148.177 170.011 139.183 184.827 139.951 201.013L142.599 259.158L139.457 259.303V259.298Z"
            fill="#1D1D1B"
          />
          <path
            d="M220.654 52.3237L156.644 79.8423L157.706 82.3288L221.716 54.8102L220.654 52.3237Z"
            fill="#1D1D1B"
          />
          <path
            d="M187.837 116.758C183.589 116.758 181.328 113.926 180.24 111.834C177.035 105.67 178.226 95.5933 181.427 93.6681C183.823 92.2253 186.974 93.1631 190.795 96.4545C193.465 98.754 195.487 101.378 195.573 101.491L194.148 102.591C192.035 99.8361 185.792 93.1451 182.353 95.2146C180.317 96.441 178.847 105.251 181.836 110.995C183.976 115.107 187.657 116.023 192.781 113.714L193.519 115.36C191.316 116.352 189.437 116.753 187.837 116.753V116.758Z"
            fill="#1D1D1B"
          />
          <path
            d="M180.395 110.98L173.064 124.035L174.631 124.92L181.961 111.865L180.395 110.98Z"
            fill="#1D1D1B"
          />
          <path
            d="M195.662 99.4125C192.803 99.4125 190.646 98.5332 190.601 98.5107L191.297 96.847C191.621 96.9822 199.316 100.084 204.14 93.0731C205.403 91.2426 205.866 89.2632 205.807 87.4056C202.629 89.1956 199.325 89.6284 196.966 87.3695C194.817 85.3135 194.893 81.9725 197.141 79.6009C199.078 77.5584 201.69 77.4412 203.96 79.2898C205.394 80.4576 206.531 82.2971 207.129 84.3712C207.853 83.785 208.599 83.0997 209.363 82.2971C216.793 74.506 219.665 61.6425 219.868 52.9946L221.666 53.0352C221.589 56.2815 220.708 73.0091 210.666 83.5416C209.691 84.5606 208.63 85.5029 207.524 86.3009C207.853 89.0783 207.187 91.8242 205.623 94.0921C202.679 98.3709 198.764 99.4034 195.666 99.4034L195.662 99.4125ZM200.616 79.7948C199.932 79.7948 199.186 80.0698 198.44 80.8543C196.682 82.7074 197.006 84.9258 198.202 86.071C199.645 87.4507 202.292 87.5859 205.56 85.5074C205.093 83.4379 204.023 81.6795 202.818 80.6965C202.31 80.2817 201.519 79.7948 200.611 79.7948H200.616Z"
            fill="#1D1D1B"
          />
          <path
            d="M217.464 92.532C215.078 92.532 212.812 86.2468 211.936 83.4964L213.221 83.0861C214.606 87.4236 216.637 91.2921 217.469 91.1794C217.478 91.1794 218.57 90.9765 219.056 84.5289L220.4 84.6281C220 89.9801 219.168 92.3381 217.626 92.523C217.572 92.5275 217.523 92.532 217.469 92.532H217.464Z"
            fill="#1D1D1B"
          />
          <path
            d="M239.039 114.603C238.814 114.494 233.465 111.866 231.919 107.799L233.178 107.316C234.531 110.874 239.569 113.358 239.623 113.381L239.039 114.598V114.603Z"
            fill="#1D1D1B"
          />
          <path
            d="M213.287 84.9108L211.453 85.0951L211.498 85.5437L213.332 85.3595L213.287 84.9108Z"
            fill="#1D1D1B"
          />
          <path
            d="M214.582 87.6616L211.833 87.8453L211.863 88.2952L214.612 88.1115L214.582 87.6616Z"
            fill="#1D1D1B"
          />
          <path
            d="M162.464 170.573L159.963 171.584L195.812 260.81L198.314 259.798L162.464 170.573Z"
            fill="#1D1D1B"
          />
          <path
            d="M145.617 219.647L143.196 220.839L163.462 262.223L165.883 261.031L145.617 219.647Z"
            fill="#1D1D1B"
          />
          <path
            d="M158.437 162.91L157.115 164.733L235.737 222.093L237.059 220.27L158.437 162.91Z"
            fill="#1D1D1B"
          />
          <path
            d="M174.049 176.128L173.33 176.669L236.796 261.364L237.515 260.822L174.049 176.128Z"
            fill="#1D1D1B"
          />
          <path
            d="M173.634 176.584L173.221 176.762L209.335 260.915L209.748 260.737L173.634 176.584Z"
            fill="#1D1D1B"
          />
          <path
            d="M238.329 221.79L236.068 220.311L256.813 188.407L263.515 167.567L281.958 151.056L282.632 152.941C282.745 153.256 285.352 160.763 280.057 165.443C274.658 170.214 274.82 172.811 275 173.492C275.234 174.384 276.052 174.732 276.695 174.867L276.137 177.513C274.204 177.108 272.842 175.89 272.393 174.177C271.853 172.112 272.433 168.573 278.272 163.41C280.74 161.232 280.825 158.022 280.551 155.934L265.857 169.091L259.267 189.574L238.325 221.785L238.329 221.79Z"
            fill="#1D1D1B"
          />
          <path
            d="M287.928 184.944C284.071 180.688 278.511 176.675 272.551 177.333C269.579 177.45 267.633 179.782 266.347 182.356C264.972 185.088 264.077 188.195 263.435 191.301L262.644 191.238C262.684 187.902 263.214 184.624 264.428 181.486C266.356 176.603 269.467 174.254 274.843 174.43C280.107 174.623 284.849 177.595 288.387 181.319C288.998 181.959 289.519 182.563 290.063 183.294L287.928 184.949V184.944Z"
            fill="#1D1D1B"
          />
          <path
            d="M251.689 263.072L249.037 262.567L261.039 198.966L295.134 179.966L294.931 182.482C294.891 182.983 293.794 194.755 280.197 200.711L279.118 198.231C287.398 194.606 290.486 188.537 291.614 185.02L263.453 200.711L251.689 263.067V263.072Z"
            fill="#1D1D1B"
          />
          <path
            d="M271.719 259.911L268.716 213.088L316.79 194.913L329.354 168.23C325.196 169.659 319.159 172.224 317.456 174.997C314.561 179.718 306.366 193.416 306.285 193.551L305.935 194.133L286.566 196.04L286.3 193.348L304.321 191.576C305.926 188.898 312.601 177.752 315.159 173.577C316.525 171.35 319.73 169.172 324.679 167.107C328.244 165.619 331.395 164.744 331.529 164.708L334.361 163.928L318.759 197.054L318.247 197.248L271.53 214.909L274.407 259.735L271.714 259.907L271.719 259.911Z"
            fill="#1D1D1B"
          />
          <path
            d="M315.677 173.101L291.575 186.063L292.85 188.448L316.951 175.485L315.677 173.101Z"
            fill="#1D1D1B"
          />
          <path
            d="M309.555 184.78L299.933 191.151L300.428 191.904L310.05 185.532L309.555 184.78Z"
            fill="#1D1D1B"
          />
          <path
            d="M318.346 197.212L317.209 194.759L322.275 192.392L333.683 176.995C331.607 177.613 328.51 179.249 325.053 183.51L322.958 181.801C330.159 172.919 336.56 173.951 336.825 174.001L338.969 174.384L324.028 194.552L318.342 197.208L318.346 197.212Z"
            fill="#1D1D1B"
          />
          <path
            d="M325.627 167.884L312.88 197.659L313.706 198.015L326.453 168.24L325.627 167.884Z"
            fill="#1D1D1B"
          />
          <path
            d="M289.222 184.565L288.323 184.524L289.478 157.499L291.587 155.271L288.89 154.144L291.2 145.965L294.077 146.822C295.488 147.246 296.329 148.653 296.032 150.1L295.057 154.825L298.446 157.981L296.967 184.421L296.068 184.371L297.525 158.355L294.072 155.141L295.151 149.92C295.353 148.932 294.783 147.976 293.821 147.688L291.82 147.093L289.977 153.621L293.146 154.947L290.368 157.878L289.227 184.565H289.222Z"
            fill="#1D1D1B"
          />
          <path
            d="M297.26 169.416C296.558 168.897 293.025 166.273 292.571 165.362C292.261 164.74 292.481 164.041 292.747 163.527H291.178V161.205L295.327 160.727L294.832 164.447L297.354 164.348L297.39 165.249L293.803 165.394L294.1 163.175L293.929 163.378C293.708 163.64 293.138 164.487 293.376 164.97C293.637 165.493 296.113 167.468 297.79 168.699L297.26 169.425V169.416ZM292.081 162.625H294.171L294.288 161.751L292.081 162.008V162.625Z"
            fill="#1D1D1B"
          />
          <path
            d="M288.931 174.988L287.753 173.216L288.904 169.745L289.331 169.884L288.247 173.149L289.304 174.74L288.931 174.988Z"
            fill="#1D1D1B"
          />
          <path
            d="M308.398 169.646L307.643 169.384C303.818 168.063 301.759 167.152 301.521 166.679C301.206 166.048 301.813 165.38 302.343 164.948L299.803 162.287L304.797 162.558L304.456 166.165L306.551 165.362L305.287 159.532L304.2 159.037C303.337 158.64 302.806 157.806 302.815 156.85C302.824 155.898 303.373 155.069 304.245 154.69L304.375 154.636C305.373 154.203 305.67 153.288 305.616 152.575C305.562 151.863 305.126 151.002 304.074 150.731L302.06 150.208L301.705 151.552C301.584 152.016 301.264 152.409 300.833 152.625C300.401 152.841 299.902 152.86 299.457 152.675L299.403 152.652L299.597 155.2L301.548 155.786C302.105 155.953 302.496 156.435 302.541 157.017C302.586 157.598 302.28 158.139 301.754 158.392L298.086 160.173L297.695 159.361L301.363 157.58C301.651 157.44 301.651 157.17 301.642 157.089C301.637 157.008 301.593 156.742 301.287 156.647L298.747 155.88L298.392 151.259L299.795 151.836C300.001 151.922 300.231 151.913 300.428 151.813C300.631 151.714 300.774 151.538 300.828 151.317L301.413 149.108L304.294 149.857C305.539 150.181 306.407 151.223 306.506 152.508C306.604 153.793 305.908 154.956 304.726 155.466L304.595 155.52C303.948 155.799 303.714 156.394 303.71 156.859C303.705 157.323 303.93 157.923 304.568 158.216L306.065 158.901L308.389 169.646H308.398ZM304.312 167.184C305.162 167.527 306.177 167.91 307.18 168.266L306.744 166.251L304.312 167.184ZM302.348 166.273C302.518 166.399 302.928 166.602 303.485 166.841L303.647 165.128L303.269 165.38C302.842 165.664 302.424 166.075 302.348 166.273ZM302.289 166.224L302.024 163.311L303.66 165.02L303.813 163.406L302.024 163.311L302.289 166.224Z"
            fill="#1D1D1B"
          />
          <path
            d="M305.307 167.959L304.42 168.103L306.253 179.407L307.141 179.262L305.307 167.959Z"
            fill="#1D1D1B"
          />
          <path
            d="M297.479 175.52L296.652 175.178L299.138 169.163L297.079 165.168L297.879 164.753L300.127 169.122L297.479 175.52Z"
            fill="#1D1D1B"
          />
          <path
            d="M302.137 149.579L301.712 149.726L303.728 155.61L304.153 155.463L302.137 149.579Z"
            fill="#1D1D1B"
          />
          <path
            d="M301.547 160.853C301.417 160.853 301.296 160.835 301.174 160.804C300.069 160.502 299.79 158.951 299.776 158.888L300.221 158.811C300.221 158.811 300.464 160.146 301.296 160.371C302.01 160.56 303.017 159.911 304.217 158.482L304.559 158.77C303.395 160.155 302.383 160.853 301.547 160.853Z"
            fill="#1D1D1B"
          />
          <path
            d="M291.641 158.369L292.14 155.078L292.585 155.145L292.229 157.495L295.21 155.714L295.439 156.101L291.641 158.369Z"
            fill="#1D1D1B"
          />
          <path
            d="M295.183 151.624C292.427 150.957 291.335 146.768 291.29 146.588L291.726 146.475C291.735 146.515 292.796 150.578 295.286 151.182L295.183 151.619V151.624Z"
            fill="#1D1D1B"
          />
          <path
            d="M291.547 154.487L291.223 154.388L291.992 151.989L290.733 151.696L292.626 149.099L292.896 149.297L291.304 151.48L292.428 151.741L291.547 154.487Z"
            fill="#1D1D1B"
          />
          <path
            d="M306.263 159.325C306.002 159.325 305.737 159.293 305.467 159.226L305.575 158.788C306.169 158.937 306.749 158.897 307.207 158.671C307.706 158.428 308.052 157.941 308.066 157.463C308.075 157.255 308.016 157.048 307.908 156.863C307.908 156.895 307.908 156.926 307.908 156.958C307.908 157.58 307.409 158.153 306.798 158.238C306.164 158.329 305.512 157.869 305.414 157.255C305.355 156.904 305.476 156.548 305.751 156.259C305.948 156.052 306.205 155.903 306.474 155.826C306.375 155.786 306.272 155.754 306.169 155.731C305.409 155.556 304.613 155.447 303.957 155.826L303.732 155.434C304.524 154.974 305.418 155.091 306.268 155.29C306.668 155.38 307.081 155.578 307.391 155.867C308.052 156.11 308.538 156.782 308.511 157.481C308.488 158.135 308.052 158.761 307.396 159.077C307.054 159.244 306.668 159.329 306.254 159.329L306.263 159.325ZM306.874 156.214C306.587 156.214 306.286 156.345 306.079 156.561C305.975 156.669 305.814 156.89 305.859 157.174C305.921 157.544 306.349 157.842 306.74 157.787C307.131 157.733 307.463 157.35 307.463 156.953C307.463 156.692 307.342 156.453 307.153 156.254C307.095 156.236 307.036 156.227 306.978 156.218C306.946 156.218 306.91 156.214 306.874 156.214Z"
            fill="#1D1D1B"
          />
          <path
            d="M298.815 170.434L298.797 170.884L305.579 171.16L305.597 170.709L298.815 170.434Z"
            fill="#1D1D1B"
          />
          <path
            d="M319.235 171.368L318.336 171.332L318.786 160.114L315.976 156.917L318.224 151.078C318.435 150.533 318.413 149.942 318.165 149.415C317.918 148.887 317.478 148.49 316.929 148.306L314.632 147.525L311.621 151.362L313.239 151.768L312.03 155.75L315.626 156.448L310.398 159.235L309.827 163.32C309.755 163.843 309.458 164.307 309.013 164.596C308.568 164.884 308.025 164.965 307.521 164.817L306.6 164.524L306.874 163.662L307.786 163.955C308.034 164.028 308.308 163.987 308.528 163.843C308.753 163.698 308.901 163.464 308.937 163.198L309.575 158.658L312.965 156.85L310.883 156.444L312.106 152.413L310.056 151.899L314.322 146.466L317.221 147.453C318.008 147.719 318.633 148.283 318.983 149.036C319.334 149.789 319.366 150.632 319.064 151.408L317.01 156.737L319.698 159.794L319.231 171.372L319.235 171.368Z"
            fill="#1D1D1B"
          />
          <path
            d="M314.444 175.052L311.176 168.694L313.208 166.656C313.859 166.003 314.071 165.092 313.77 164.213C313.468 163.338 312.745 162.747 311.828 162.634L309.378 162.332L309.49 161.44L311.94 161.742C313.185 161.895 314.21 162.729 314.619 163.919C315.028 165.11 314.732 166.404 313.841 167.292L312.273 168.866L315.239 174.641L314.439 175.056L314.444 175.052Z"
            fill="#1D1D1B"
          />
          <path
            d="M309.792 177.671L308.902 177.531L310.219 168.974L307.445 167.91L307.764 167.066L311.221 168.392L309.792 177.671Z"
            fill="#1D1D1B"
          />
          <path
            d="M295.3 158.541L295.844 156.584L296.275 156.706L295.902 158.058L297.97 157.95L297.992 158.401L295.3 158.541Z"
            fill="#1D1D1B"
          />
          <path
            d="M312.277 153.265V153.04C312.277 153.04 312.997 153.031 313.878 152.593L313.976 152.796C313.046 153.261 312.309 153.27 312.277 153.27V153.265Z"
            fill="#1D1D1B"
          />
          <path
            d="M313.919 151.033C313.883 151.033 313.847 151.024 313.82 151.011C313.505 150.866 313.685 149.996 313.775 149.631L313.995 149.685C313.851 150.258 313.833 150.758 313.914 150.808C313.914 150.808 314.085 150.844 314.71 149.96L314.894 150.091C314.355 150.857 314.085 151.033 313.919 151.033Z"
            fill="#1D1D1B"
          />
          <path
            d="M316.742 155.434L315.717 152.697L315.861 152.598C316.225 152.345 316.782 151.804 316.728 151.435C316.719 151.362 316.692 151.349 316.679 151.34C316.521 151.259 316.099 151.394 315.861 151.511L315.344 151.768L316.863 147.791L317.281 147.954L316.144 150.921C316.387 150.857 316.67 150.826 316.881 150.939C316.993 150.997 317.133 151.119 317.169 151.371C317.259 151.994 316.571 152.611 316.252 152.859L317.155 155.276L316.733 155.434H316.742Z"
            fill="#1D1D1B"
          />
          <path
            d="M314.363 147.187C314.3 147.147 313.752 146.768 314.363 145.429L314.772 145.618C314.408 146.407 314.53 146.746 314.606 146.809L314.363 147.187Z"
            fill="#1D1D1B"
          />
          <path
            d="M314.179 159.55L313.307 157.183L313.725 157.026L314.408 158.883L317.02 157.278L317.253 157.661L314.179 159.55Z"
            fill="#1D1D1B"
          />
          <path
            d="M310.474 161.796L310.056 161.962L312.484 168.121L312.902 167.955L310.474 161.796Z"
            fill="#1D1D1B"
          />
          <path
            d="M307.216 166.129L307.167 165.908C307.167 165.908 307.554 165.818 307.891 165.479L308.048 165.637C307.661 166.025 307.234 166.124 307.212 166.129H307.216Z"
            fill="#1D1D1B"
          />
          <path
            d="M311.853 169.338L310.109 170.993L310.418 171.32L312.162 169.665L311.853 169.338Z"
            fill="#1D1D1B"
          />
          <path
            d="M309.679 165.11C309.657 165.11 309.63 165.105 309.607 165.092C309.549 165.065 309.369 164.983 309.607 164.104L309.823 164.163C309.747 164.442 309.702 164.731 309.711 164.857C309.765 164.812 309.877 164.699 310.075 164.393L310.264 164.519C309.989 164.934 309.819 165.11 309.679 165.11Z"
            fill="#1D1D1B"
          />
          <path
            d="M292.918 151.823C292.891 151.823 292.864 151.818 292.841 151.805C292.675 151.728 292.675 151.399 292.837 150.822L293.052 150.885C292.971 151.169 292.927 151.471 292.945 151.579C293.003 151.543 293.147 151.412 293.448 150.975L293.632 151.101C293.25 151.66 293.052 151.823 292.918 151.823Z"
            fill="#1D1D1B"
          />
          <path
            d="M290.975 150.772C290.948 150.772 290.926 150.767 290.903 150.758C290.705 150.677 290.746 150.253 290.813 149.911L291.033 149.956C290.989 150.186 290.971 150.434 290.989 150.528C291.038 150.492 291.168 150.375 291.42 149.965L291.609 150.086C291.276 150.623 291.096 150.772 290.966 150.772H290.975Z"
            fill="#1D1D1B"
          />
          <path
            d="M292.094 152.945C292.049 152.945 292.022 152.945 292.018 152.945L292.036 152.72C292.036 152.72 292.588 152.756 292.962 152.427L293.11 152.593C292.75 152.914 292.278 152.945 292.094 152.945Z"
            fill="#1D1D1B"
          />
          <path
            d="M299.399 154.095C299.341 154.095 299.305 154.095 299.296 154.09L299.318 153.865C299.318 153.865 299.691 153.901 299.975 153.703L300.105 153.887C299.849 154.068 299.552 154.095 299.395 154.095H299.399Z"
            fill="#1D1D1B"
          />
          <path
            d="M301.827 153.166C301.827 153.166 301.795 153.166 301.777 153.157C301.71 153.139 301.53 153.085 301.678 152.088L301.898 152.12C301.853 152.427 301.836 152.733 301.849 152.882C301.898 152.814 301.988 152.67 302.132 152.354L302.339 152.449C302.083 153.013 301.943 153.162 301.822 153.162L301.827 153.166Z"
            fill="#1D1D1B"
          />
          <path
            d="M315.087 170.795C314.656 170.795 314.202 170.687 313.775 170.457C312.606 169.83 312.525 167.946 312.521 167.729V167.477L312.727 167.342C312.907 167.229 314.512 166.241 315.681 166.868C316.238 167.166 316.674 167.626 316.908 168.162C317.155 168.735 317.146 169.321 316.881 169.817C316.544 170.453 315.851 170.795 315.083 170.795H315.087ZM313.442 167.973C313.5 168.5 313.698 169.398 314.202 169.664C314.939 170.06 315.806 169.934 316.094 169.393C316.224 169.145 316.224 168.834 316.09 168.523C315.937 168.176 315.645 167.869 315.262 167.666C314.759 167.396 313.909 167.729 313.442 167.977V167.973Z"
            fill="#1D1D1B"
          />
          <path
            d="M313.378 77.7567H279.454L279.431 77.5583C279.418 77.4501 278.294 66.5389 287.936 65.299C291.779 64.803 293.973 65.7814 295.168 66.7553C295.438 66.593 295.735 66.4487 296.049 66.3225C297.272 65.831 299.416 65.3846 302.671 66.0384C306.231 66.7508 307.714 68.3063 308.334 69.4876C309.746 69.5102 311.391 69.8979 312.762 70.723C315.302 72.2515 317.639 74.6366 317.199 76.2373C316.92 77.2472 315.634 77.7612 313.382 77.7612L313.378 77.7567ZM279.863 77.3058H313.378C315.378 77.3058 316.547 76.8955 316.763 76.1155C317.127 74.7945 314.897 72.5356 312.528 71.1108C311.247 70.3398 309.786 69.9881 308.537 69.934C308.671 70.2857 308.734 70.5833 308.757 70.7771C308.892 71.8141 308.519 72.743 307.826 73.0856C307.229 73.3787 306.523 73.1848 305.889 72.5491C304.81 71.467 305.098 70.732 305.368 70.3894C305.777 69.8664 306.694 69.5553 307.826 69.5012C307.197 68.4461 305.777 67.125 302.585 66.4848C299.277 65.822 296.957 66.2684 295.523 67.0754C295.946 67.4902 296.198 67.8735 296.333 68.1079C296.917 69.1585 296.948 70.3082 296.414 70.971C296.036 71.4399 295.429 71.5887 294.746 71.3768C293.6 71.0251 293.042 70.0783 293.321 68.9691C293.505 68.2477 294.022 67.5624 294.777 67.0213C293.64 66.1376 291.577 65.29 287.999 65.7498C284.147 66.2458 281.611 68.3649 280.461 72.0441C279.732 74.3796 279.822 76.6385 279.867 77.3103L279.863 77.3058ZM308.051 69.925C306.941 69.952 306.06 70.2271 305.723 70.6599C305.332 71.1604 305.772 71.7871 306.208 72.2244C306.703 72.7204 307.206 72.8782 307.629 72.6753C308.141 72.4228 308.424 71.6654 308.312 70.8312C308.285 70.6329 308.217 70.3082 308.051 69.925ZM295.128 67.3144C294.346 67.8509 293.896 68.4957 293.752 69.0728C293.532 69.943 293.964 70.6599 294.872 70.9394C295.384 71.0973 295.793 71.0071 296.058 70.6779C296.472 70.1639 296.422 69.1946 295.937 68.3199C295.811 68.0899 295.559 67.7112 295.128 67.3099V67.3144Z"
            fill="#E29C00"
          />
          <path
            d="M168.966 150.763C164.332 150.763 161.239 148.608 160.241 144.676C160.196 144.496 160.156 144.311 160.12 144.126C156.285 144.644 151.669 143.251 147.107 138.81C143.654 135.446 142.553 130.703 143.951 125.532C143.722 125.45 143.497 125.369 143.272 125.284C139.087 123.661 131.994 119.382 131.509 108.944L132.857 108.881C133.311 118.552 139.883 122.52 143.762 124.021C143.956 124.098 144.153 124.17 144.351 124.238C144.477 123.868 144.621 123.498 144.774 123.124C147.601 116.226 153.993 110.648 159.05 110.648C159.072 110.648 159.095 110.648 159.117 110.648C161.122 110.666 163.851 111.55 165.437 115.648C166.494 118.367 165.963 120.982 163.954 123.002C160.237 126.74 152.181 127.917 145.237 125.933C143.538 132.169 146.176 136.01 148.055 137.836C152.267 141.939 156.483 143.233 159.953 142.769C159.625 138.188 161.832 133.003 164.309 131.704C165.464 131.1 166.638 131.339 167.519 132.362C169.604 134.779 169.02 138.521 166.125 141.267C164.848 142.48 163.253 143.391 161.446 143.864C161.477 144.027 161.513 144.184 161.554 144.347C162.412 147.728 165.042 149.482 169.195 149.415L169.218 150.767C169.137 150.767 169.051 150.767 168.97 150.767L168.966 150.763ZM165.55 132.728C165.352 132.728 165.145 132.786 164.92 132.903C163.109 133.855 161.032 138.35 161.27 142.498C162.785 142.074 164.116 141.303 165.19 140.284C167.204 138.373 168.301 135.343 166.489 133.251C166.188 132.899 165.882 132.728 165.55 132.728ZM145.628 124.643C152.128 126.501 159.589 125.464 162.988 122.046C164.646 120.378 165.046 118.39 164.17 116.135C163.113 113.412 161.405 112.019 159.095 111.996H159.041C154.559 111.996 148.603 117.308 146.01 123.633C145.87 123.976 145.74 124.31 145.628 124.643Z"
            fill="#1D1D1B"
          />
          <path
            d="M322.692 170.2L320.683 169.19L324.994 160.547C323.358 160.763 320.827 161.62 318.907 164.609L317.02 163.387C320.854 157.418 326.742 158.247 326.994 158.288L328.518 158.522L322.697 170.2H322.692Z"
            fill="#1D1D1B"
          />
          <path
            d="M289.27 170.982L289.254 171.433L297.034 171.71L297.05 171.259L289.27 170.982Z"
            fill="#1D1D1B"
          />
          <path
            d="M170.289 176.649C173.22 173.979 172.659 168.57 169.036 164.567C165.413 160.565 160.1 159.485 157.168 162.155C154.237 164.825 154.797 170.234 158.421 174.236C162.044 178.238 167.357 179.319 170.289 176.649Z"
            fill="#E21C00"
          />
          <path
            d="M168.093 174.222C169.894 172.581 169.4 169.094 166.99 166.431C164.581 163.769 161.167 162.941 159.366 164.582C157.565 166.222 158.058 169.71 160.468 172.372C162.878 175.034 166.291 175.862 168.093 174.222Z"
            fill="white"
          />
          <path
            d="M165.946 171.852C166.739 171.13 166.388 169.449 165.163 168.095C163.938 166.742 162.303 166.23 161.51 166.952C160.718 167.673 161.069 169.355 162.294 170.708C163.519 172.061 165.154 172.573 165.946 171.852Z"
            fill="#66C337"
          />
        </g>
        <path
          d="M234.519 433.378L231.262 467.393H166.081V466.642C172.085 465.132 174.712 462.751 174.712 453.836V392.333C174.712 384.176 172.212 381.788 166.081 380.286V379.535H229.765L232.018 409.412H231.27C226.763 392.588 217.885 382.674 202.995 382.674H195.239V420.835H200.12C211.26 420.835 216.507 414.556 218.761 405.273H219.509V440.423H218.761C216.507 430.757 211.132 423.982 200.12 423.982H195.239V464.276H202.995C220.138 464.276 227.894 454.738 233.77 433.4H234.519V433.378Z"
          fill="#2B2B2B"
        />
        <path
          d="M45.0305 238.195L41.7741 295.56C41.0255 307.99 43.7729 310.243 53.1604 312.128V312.879H28.2617V312.128C35.1414 310.123 37.0204 306.856 37.6493 295.688L40.8982 238.818C41.3998 231.037 39.1465 227.65 32.7683 225.765V225.014H61.9191L84.6917 281.748L107.584 225.014H135.357V225.765C129.226 227.275 126.599 230.662 127.1 239.321L130.357 299.323C130.858 306.856 133.486 310.619 139.49 312.128V312.879H100.33V312.128C107.09 310.123 110.092 305.729 109.59 295.936L106.836 237.947L76.3073 314.141H75.6785L45.0305 238.195Z"
          fill="#2B2B2B"
        />
        <path
          d="M105.959 324.213V338.362H105.57C104.559 337.739 103.392 337.424 100.974 337.424C95.9879 337.424 92.6341 339.767 92.6341 349.853V360.173C92.6341 364.003 95.127 365.408 98.0091 366.039V366.429H74.0088V366.039C76.1872 365.1 77.8267 363.38 77.8267 360.173V337.191C77.8267 332.97 76.6589 330.859 74.0088 329.996V329.605L92.0128 324.213V343.447H92.4769C93.0234 329.372 98.084 324.213 104.792 324.213H105.959Z"
          fill="#2B2B2B"
        />
        <path
          d="M151.31 356.658C149.985 364.086 145 367.601 138.921 367.601C133.463 367.601 130.274 364.867 129.488 360.564L129.256 360.489C128.006 364.552 124.428 367.601 118.896 367.601C112.428 367.601 108.685 362.757 108.685 357.514C108.685 350.402 114.606 345.55 123.881 343.598L129.024 342.501V341.172C129.024 336.245 127.31 331.949 120.842 331.949C116.013 331.949 112.735 333.826 110.713 339.767L110.167 339.609C111.492 330.071 117.728 324.205 127.699 324.205C139.153 324.205 143.832 331.633 143.832 342.11V355.87C143.832 358.604 145.232 359.775 146.796 359.775C148.046 359.775 149.835 359.152 150.539 356.568L151.318 356.643L151.31 356.658ZM129.024 358.926V344.852L127.931 345.085C123.492 345.948 122.01 349.23 122.01 353.369C122.01 357.507 123.956 359.783 126.838 359.783C127.849 359.783 128.553 359.55 129.024 358.919V358.926Z"
          fill="#2B2B2B"
        />
        <path
          d="M197.595 366.039V366.429H176.709V366.039C178.348 365.258 178.97 363.771 178.97 360.954V335.546C178.97 332.339 177.644 330.777 175.384 330.777C173.595 330.777 172.419 331.558 171.566 332.73V360.954C171.566 363.771 172.187 365.258 173.827 366.039V366.429H152.94V366.039C155.119 365.1 156.758 363.38 156.758 360.173V336.17C156.758 332.414 155.748 330.777 152.94 329.913V329.523L171.566 324.205V331.318C173.28 326.706 177.255 324.205 182.473 324.205C189.562 324.205 193.769 328.269 193.769 335.539V360.166C193.769 363.373 195.409 365.093 197.587 366.031L197.595 366.039Z"
          fill="#2B2B2B"
        />
        <path
          d="M122.399 420.302L142.582 381.683H141.803C134.474 381.683 127.617 388.172 124.817 397.868H124.428L126.764 379.573H159.807V379.888L139.625 418.507H140.014C147.882 418.507 154.896 411.785 158.168 401.075H158.557L157.389 420.617H122.399V420.302Z"
          fill="#2B2B2B"
        />
        <path
          d="M168.064 311.94V312.331H145.621V311.94C147.8 311.002 149.439 309.282 149.439 306.075V282.071C149.439 278.316 148.428 276.679 145.621 275.815V275.424L164.246 270.107V306.067C164.246 309.274 165.886 310.994 168.064 311.933V311.94ZM148.735 261.823C148.735 257.519 152.089 254.079 156.446 254.079C160.803 254.079 164.164 257.519 164.164 261.823C164.164 266.126 160.81 269.483 156.446 269.483C152.082 269.483 148.735 266.044 148.735 261.823Z"
          fill="#2B2B2B"
        />
        <path
          d="M217.001 311.94V312.331H196.115V311.94C197.754 311.159 198.376 309.672 198.376 306.856V281.447C198.376 278.241 197.051 276.678 194.79 276.678C193.001 276.678 191.825 277.459 190.972 278.631V306.856C190.972 309.672 191.593 311.159 193.233 311.94V312.331H172.347V311.94C174.525 311.001 176.165 309.281 176.165 306.074V282.071C176.165 278.316 175.154 276.678 172.347 275.815V275.424L190.972 270.107V277.219C192.686 272.608 196.661 270.107 201.879 270.107C208.969 270.107 213.176 274.17 213.176 281.44V306.067C213.176 309.274 214.815 310.994 216.994 311.933L217.001 311.94Z"
          fill="#2B2B2B"
        />
        <path
          d="M219.734 291.767C219.734 278.864 228.695 270.114 241.085 270.114C253.474 270.114 262.435 278.871 262.435 291.767C262.435 304.663 253.474 313.502 241.085 313.502C228.695 313.502 219.734 304.67 219.734 291.767ZM246.849 291.767C246.849 278.083 245.06 273.163 241.085 273.163C237.11 273.163 235.32 278.09 235.32 291.767C235.32 305.444 237.11 310.371 241.085 310.371C245.06 310.371 246.849 305.444 246.849 291.767Z"
          fill="#2B2B2B"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_2192_1993"
        x="0.000976562"
        y="0"
        width="350.876"
        height="494.006"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.508333 0 0 0 0 0.508333 0 0 0 0 0.508333 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2192_1993"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.3875 0 0 0 0 0.3875 0 0 0 0 0.3875 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2192_1993"
          result="effect2_dropShadow_2192_1993"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2192_1993"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2192_1993">
        <rect
          x="12.001"
          y="12"
          width="326.876"
          height="470.006"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_2192_1993">
        <path
          d="M338.954 429.582L287.961 430.414L288.809 482.407L329.803 481.739C335.325 481.649 339.729 477.099 339.639 471.577L338.954 429.582Z"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_2192_1993">
        <rect
          width="216.038"
          height="251.43"
          fill="white"
          transform="translate(123.04 16.6611)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: "tema_minoranze" };
</script>

<style lang="scss" scoped>
.card-tema {
  &__arrow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__background {
    transition: fill 0.3s ease;
  }
  &:hover {
    .card-tema__arrow {
      opacity: 1;
    }

    .card-tema__background {
      fill: var(--background-card-color);
    }
  }
}
</style>
