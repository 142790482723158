<template>
  <main class="themes-page">
    <ConstitutionHeader class="themes-page__header" />

    <div class="themes-page__links">
      <router-link
        v-for="(label, theme) of themes"
        :key="theme"
        :aria-label="`Va a ${label}`"
        :class="['themes-page__links__link', theme]"
        :to="{ name: 'theme', query: { theme } }"
        :style="{ '--dog-ear-bg-inner': `var(--${theme})` }"
      >
        <img
          :class="['themes-page__links__link__img']"
          :key="theme"
          :src="`${$baseUrl}images/themes/btn-${theme}.png`"
          :alt="label"
          v-if="$tvaMq !== 'desktop'"
        />

        <component
          :is="theme"
          v-if="$tvaMq === 'desktop'"
          :class="['themes-page__links__link__img']"
          :style="{ '--background-card-color': `var(--${theme})` }"
        />

        <DogEar
          v-if="$tvaMq === 'desktop'"
          class="themes-page__links__link__dog-ear"
          :showArrow="true"
          gradientColor="var(--basic-black)"
          largeArrow
        />

        <!-- <svgicon
          :name="theme"
          :class="['themes-page__links__link__img']"
          :style="{ '--background-card-color': `var(--${theme})` }"
          original
        ></svgicon> -->
      </router-link>
    </div>
    <Transition name="fade">
      <ModalSocialShare v-if="getModalState" />
    </Transition>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import ConstitutionHeader from "../components/ConstitutionHeader.vue";
import ModalSocialShare from "../components/ModalSocialShare.vue";
import DogEar from "../components/DogEar.vue";
import tema_lavoro from "../components/cardsThemes/tema_lavoro.vue";
import tema_unità from "../components/cardsThemes/tema_unità.vue";
import tema_minoranze from "../components/cardsThemes/tema_minoranze.vue";
import tema_uguaglianza from "../components/cardsThemes/tema_uguaglianza.vue";

export default {
  components: {
    ConstitutionHeader,
    ModalSocialShare,
    DogEar,
    tema_lavoro,
    tema_minoranze,
    tema_unità,
    tema_uguaglianza,
  },
  // data() {
  //   return {
  //     cards: {
  //       tema_uguaglianza: import(
  //         `@/public/images/themes/card-tema_uguaglianza.svg`
  //       ),
  //       tema_lavoro: import(`@/public/images/themes/card-tema_lavoro.svg`),
  //       tema_unità: import(`@/public/images/themes/card-tema_unità.svg`),
  //       tema_minoranze: import(
  //         `@/public/images/themes/card-tema_minoranze.svg`
  //       ),
  //     },
  //   };
  // },
  computed: {
    themes() {
      return this.$t("themes");
    },

    ...mapGetters(["getModalState"]),
  },
  // methods: {
  //   async dynamicImport(theme) {
  //     const element = await import(
  //       `@/public/images/themes/card-${theme}.svg`
  //     );
  //     return element;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.themes-page {
  padding: 0 toRem(130);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__links {
    padding: calc(#{$page-header} + #{toRem(76)}) 0 toRem(76);

    &__link {
      .tablet &,
      .mobile & {
        @include drop-shadow-1;
      }

      $margin-h: toRem(36);
      $margin-v: toRem(40);

      display: inline-block;
      width: calc(50% - #{$margin-h} * 0.5);

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: $margin-v;
      }

      &:nth-child(2n - 1) {
        margin-right: $margin-h;
      }

      &__img {
        width: 100%;
      }
    }
  }
}

.desktop {
  .themes-page {
    overflow: hidden;
    min-height: calc(var(--vh, 1vh) * 100);

    &__links {
      --dog-ear-size: 2.5rem;

      display: flex;
      align-items: flex-start;
      padding: calc(#{$page-header} + 10vh) 0 1rem;

      &__link {
        position: relative;
        margin-bottom: 0;
        margin-right: 0;
        transition: transform 0.3s ease;

        --offset: 0;

        &.tema_uguaglianza {
          z-index: 1;
          --offset: 0px;
          transform: rotate(7.93deg) translateY(var(--offset));
        }

        &.tema_lavoro {
          --offset: 90px;
          transform: rotate(-9deg) translateY(var(--offset));
        }

        &.tema_minoranze {
          --offset: -45px;
          transform: rotate(-1deg) translateY(var(--offset));
        }

        &.tema_unità {
          --offset: 90px;
          transform: rotate(-11deg) translateY(var(--offset));
        }

        &:hover {
          transform: rotate(0deg) translateY(var(--offset));

          .themes-page__links__link__dog-ear {
            opacity: 1;
          }
        }

        &__dog-ear {
          position: absolute;
          bottom: 4.3%;
          right: 3.5%;
          opacity: 0;
          transition: opacity 0.3s;

          @media screen and (min-width: 1441px) {
            bottom: 3.5%;
          }

          // display: inline-block;
        }

        // &:hover {
        //   // .themes-page__links__link__img {
        //   //   &::v-deep > g > g > path:first-of-type {
        //   //     fill: var(--background-card-color) !important;
        //   //   }
        //   // }
        // }
      }
    }
  }
}
</style>
