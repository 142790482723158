<template>
  <svg
    viewBox="0 0 351 493"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="card-tema"
  >
    <g filter="url(#filter0_dd_2192_937)">
      <g clip-path="url(#clip0_2192_937)">
        <path
          d="M11.1914 19.8686C11.1914 14.3457 15.6686 9.86859 21.1914 9.86859L328.191 9.86859C333.714 9.86859 338.191 14.3457 338.191 19.8686V447.369L338.019 470.578C337.978 476.184 333.337 480.66 327.733 480.5L305.691 479.869H21.1914C15.6686 479.869 11.1914 475.391 11.1914 469.869L11.1914 19.8686Z"
          class="card-tema__background"
          fill="white"
        />
        <path
          d="M172.639 142.182L160.657 163.827C160.657 163.827 158.695 160.328 155.431 162.08C152.166 163.832 155.214 171.698 155.214 171.698L148.68 183.069C148.68 183.069 133.212 174.98 136.481 159.455C139.751 143.929 154.782 141.086 154.782 141.086L172.644 142.182H172.639Z"
          fill="#F89971"
        />
        <path
          d="M180.481 183.508L180.044 204.936C180.044 204.936 165.667 203.189 165.883 194.222C166.1 185.255 173.942 183.291 180.476 183.508H180.481Z"
          fill="#F89971"
        />
        <path
          d="M270.012 138.466C270.012 138.466 264.785 139.778 261.515 144.59C258.246 149.402 254.543 166.018 254.543 166.018L252.803 179.135C252.803 179.135 255.851 189.632 264.785 190.289L286.35 188.32L294.409 149.402C294.409 149.402 293.539 140.001 286.567 139.344C279.594 138.688 270.012 138.471 270.012 138.471V138.466Z"
          fill="#F89971"
        />
        <path
          d="M235.811 249.317L249.533 252.816L254.544 265.282L241.037 264.625L235.811 249.317Z"
          fill="#F89971"
        />
        <path
          d="M275.893 240.355L291.577 237.729L289.178 262.656L298.112 258.284L301.16 264.625L283.951 264.403L275.893 240.355Z"
          fill="#F89971"
        />
        <path
          d="M172.856 182.635L159.133 184.604L165.451 191.162C165.451 191.162 169.37 182.2 172.856 182.635Z"
          fill="#F89971"
        />
        <path
          d="M196.815 125.186L207.927 125.566L197.691 134.75L193.983 129.721L196.815 125.186Z"
          fill="#F89971"
        />
        <path
          d="M185.708 134.533L179.68 161.937L186.362 160.767L193.983 129.721L185.708 134.533Z"
          fill="#F78757"
        />
        <path
          d="M217.84 133.22L216.64 159.677L186.362 160.772L180.482 181.105L180.919 191.162L193.772 178.701C193.772 178.701 192.031 173.016 194.642 171.269C197.253 169.522 200.96 168.644 202.484 168.644L196.604 174.329L202.484 178.484L207.711 173.894C207.711 173.894 208.148 186.795 197.253 182.639L180.26 201.225L180.044 204.941L191.156 220.466L203.571 189.198L222.958 188.981L222.742 139.566L217.844 133.225L217.84 133.22Z"
          fill="#F78757"
        />
        <path
          d="M193.983 78.7764C193.983 78.7764 205.744 78.5593 203.566 91.8938L219.251 92.1109C219.251 92.1109 220.559 87.5214 219.467 85.113C218.376 82.7047 215.765 78.7715 212.495 78.5544C209.226 78.3372 193.979 78.7715 193.979 78.7715L193.983 78.7764Z"
          fill="#F78757"
        />
        <path
          d="M268.921 88.1776L268.483 96.4883C268.483 96.4883 266.305 91.0202 264.127 91.6766C261.949 92.333 261.732 98.0181 263.473 99.3309L253.89 106.985L252.149 92.1158L268.921 88.1826V88.1776Z"
          fill="#F78757"
        />
        <path
          d="M241.799 122.072C248.477 122.072 253.89 116.638 253.89 109.936C253.89 103.234 248.477 97.8009 241.799 97.8009C235.122 97.8009 229.709 103.234 229.709 109.936C229.709 116.638 235.122 122.072 241.799 122.072Z"
          fill="#F78757"
        />
        <path
          d="M272.407 189.41L268.921 194.222L270.883 194.005L266.305 207.339L266.089 219.8L273.715 207.779L275.239 194.005L277.634 194.222L275.239 189.627L272.407 189.41Z"
          fill="#F78757"
        />
        <path
          d="M273.06 108.076L261.516 107.202L265.002 111.358L273.06 108.076Z"
          fill="#F89971"
        />
        <path
          d="M272.406 189.41L281.63 181.465L279.885 189.336L272.406 189.41Z"
          fill="#F78757"
        />
        <path
          d="M237.187 224.03L239.222 230.154H256.357L254.032 223.739L247.059 223.156C247.059 223.156 252.866 219.657 247.64 218.784C242.413 217.91 238.057 219.075 238.637 221.118C239.217 223.161 241.253 223.744 241.253 223.744L237.187 224.035V224.03Z"
          fill="#F89971"
        />
        <path
          d="M270.012 138.466C270.012 138.466 254.617 154.352 288.892 140.652C288.892 140.652 283.086 137.735 270.012 138.466Z"
          fill="#F78757"
        />
        <path
          d="M293.54 145.316C293.54 145.316 282.211 151.144 290.344 166.887C290.344 166.887 296.15 151.726 293.54 145.316Z"
          fill="#F78757"
        />
        <path
          d="M238.998 261.477H161.701L161.632 260.564C161.622 260.465 161.474 258.126 163.539 255.886C166.022 253.196 170.497 251.834 176.844 251.834H235.625V253.808H176.844C169.71 253.808 166.48 255.639 165.029 257.179C164.223 258.032 163.864 258.886 163.712 259.503H238.998V261.477Z"
          fill="#F78757"
        />
        <path
          d="M163.919 257.85H171.688C173.512 257.85 175.183 256.843 176 255.244L176.118 255.007H166.879"
          fill="#F78757"
        />
        <path
          d="M185.94 257.263H183.103C182.818 257.263 182.582 257.031 182.582 256.739V255.688C182.582 255.402 182.813 255.165 183.103 255.165H185.94C186.225 255.165 186.461 255.397 186.461 255.688V256.739C186.461 257.026 186.23 257.263 185.94 257.263ZM183.103 255.417C182.951 255.417 182.828 255.54 182.828 255.693V256.744C182.828 256.897 182.951 257.021 183.103 257.021H185.94C186.093 257.021 186.216 256.897 186.216 256.744V255.693C186.216 255.54 186.093 255.417 185.94 255.417H183.103Z"
          fill="#F78757"
        />
        <path
          d="M193.148 257.263H190.311C190.026 257.263 189.79 257.031 189.79 256.739V255.688C189.79 255.402 190.021 255.165 190.311 255.165H193.148C193.433 255.165 193.669 255.397 193.669 255.688V256.739C193.669 257.026 193.438 257.263 193.148 257.263ZM190.311 255.417C190.159 255.417 190.036 255.54 190.036 255.693V256.744C190.036 256.897 190.159 257.021 190.311 257.021H193.148C193.301 257.021 193.424 256.897 193.424 256.744V255.693C193.424 255.54 193.301 255.417 193.148 255.417H190.311Z"
          fill="#F78757"
        />
        <path
          d="M200.351 257.263H197.514C197.229 257.263 196.993 257.031 196.993 256.739V255.688C196.993 255.402 197.224 255.165 197.514 255.165H200.351C200.637 255.165 200.873 255.397 200.873 255.688V256.739C200.873 257.026 200.641 257.263 200.351 257.263ZM197.514 255.417C197.362 255.417 197.239 255.54 197.239 255.693V256.744C197.239 256.897 197.362 257.021 197.514 257.021H200.351C200.504 257.021 200.627 256.897 200.627 256.744V255.693C200.627 255.54 200.504 255.417 200.351 255.417H197.514Z"
          fill="#F78757"
        />
        <path
          d="M207.56 257.263H204.723C204.438 257.263 204.202 257.031 204.202 256.739V255.688C204.202 255.402 204.433 255.165 204.723 255.165H207.56C207.846 255.165 208.082 255.397 208.082 255.688V256.739C208.082 257.026 207.85 257.263 207.56 257.263ZM204.723 255.417C204.571 255.417 204.448 255.54 204.448 255.693V256.744C204.448 256.897 204.571 257.021 204.723 257.021H207.56C207.713 257.021 207.836 256.897 207.836 256.744V255.693C207.836 255.54 207.713 255.417 207.56 255.417H204.723Z"
          fill="#F78757"
        />
        <path
          d="M214.763 257.263H211.926C211.641 257.263 211.405 257.031 211.405 256.739V255.688C211.405 255.402 211.636 255.165 211.926 255.165H214.763C215.049 255.165 215.285 255.397 215.285 255.688V256.739C215.285 257.026 215.054 257.263 214.763 257.263ZM211.926 255.417C211.774 255.417 211.651 255.54 211.651 255.693V256.744C211.651 256.897 211.774 257.021 211.926 257.021H214.763C214.916 257.021 215.039 256.897 215.039 256.744V255.693C215.039 255.54 214.916 255.417 214.763 255.417H211.926Z"
          fill="#F78757"
        />
        <path
          d="M221.967 257.263H219.13C218.844 257.263 218.608 257.031 218.608 256.739V255.688C218.608 255.402 218.839 255.165 219.13 255.165H221.967C222.252 255.165 222.488 255.397 222.488 255.688V256.739C222.488 257.026 222.257 257.263 221.967 257.263ZM219.13 255.417C218.977 255.417 218.854 255.54 218.854 255.693V256.744C218.854 256.897 218.977 257.021 219.13 257.021H221.967C222.119 257.021 222.242 256.897 222.242 256.744V255.693C222.242 255.54 222.119 255.417 221.967 255.417H219.13Z"
          fill="#F78757"
        />
        <path
          d="M229.175 257.263H226.338C226.052 257.263 225.816 257.031 225.816 256.739V255.688C225.816 255.402 226.047 255.165 226.338 255.165H229.175C229.46 255.165 229.696 255.397 229.696 255.688V256.739C229.696 257.026 229.465 257.263 229.175 257.263ZM226.338 255.417C226.185 255.417 226.062 255.54 226.062 255.693V256.744C226.062 256.897 226.185 257.021 226.338 257.021H229.175C229.327 257.021 229.45 256.897 229.45 256.744V255.693C229.45 255.54 229.327 255.417 229.175 255.417H226.338Z"
          fill="#F78757"
        />
        <path
          d="M236.378 257.263H233.541C233.256 257.263 233.02 257.031 233.02 256.739V255.688C233.02 255.402 233.251 255.165 233.541 255.165H236.378C236.663 255.165 236.899 255.397 236.899 255.688V256.739C236.899 257.026 236.668 257.263 236.378 257.263ZM233.541 255.417C233.388 255.417 233.265 255.54 233.265 255.693V256.744C233.265 256.897 233.388 257.021 233.541 257.021H236.378C236.53 257.021 236.653 256.897 236.653 256.744V255.693C236.653 255.54 236.53 255.417 236.378 255.417H233.541Z"
          fill="#F78757"
        />
        <path
          d="M297.513 117.329H296.529V67.2777H323.164V68.2647H297.513V117.329Z"
          fill="#F78757"
        />
        <path
          d="M323.306 83.5585H297.021V84.052H323.306V83.5585Z"
          fill="#F78757"
        />
        <path
          d="M323.306 100.032H297.021V100.525H323.306V100.032Z"
          fill="#F78757"
        />
        <path
          d="M308.305 67.4803H307.813V117.477H308.305V67.4803Z"
          fill="#F78757"
        />
        <path
          d="M319.486 67.4803H318.994V117.477H319.486V67.4803Z"
          fill="#F78757"
        />
        <path
          d="M231.381 241.007H121.766L130.168 206.965L147.569 202.952C151.134 202.133 153.789 199.384 154.502 195.787C155.215 192.189 153.809 188.631 150.829 186.504L144.462 181.959C135.602 175.637 132.297 164.928 136.039 154.683C139.781 144.437 149.197 138.417 160.033 139.34L175.836 140.692L173.982 155.581C173.805 157.022 174.272 158.448 175.275 159.494C176.278 160.541 177.68 161.074 179.12 160.955L187.44 160.259L180.954 184.017L174.528 184.989C169.508 185.749 167.168 189.796 167.079 193.462C166.986 197.134 169.124 201.289 174.1 202.301L180.856 203.673L191.771 221.029L189.691 222.347L179.337 205.884L173.609 204.719C167.236 203.426 164.503 198.101 164.621 193.403C164.739 188.705 167.733 183.523 174.159 182.551L179.007 181.816L184.14 163.013L179.327 163.418C177.144 163.6 175.02 162.796 173.505 161.207C171.991 159.623 171.273 157.461 171.548 155.28L173.087 142.937L159.832 141.807C150.136 140.978 141.703 146.367 138.355 155.536C135.007 164.706 137.962 174.29 145.892 179.95L152.26 184.495C156.041 187.195 157.826 191.705 156.921 196.27C156.016 200.835 152.648 204.319 148.13 205.361L132.204 209.032L124.922 238.544H231.381V241.011V241.007Z"
          fill="#1D1D1B"
        />
        <path
          d="M231.327 189.218L224.989 143.228L212.068 128.374L218.779 89.1007C219.177 86.9194 218.636 84.6789 217.294 82.9417C215.952 81.1997 213.926 80.114 211.738 79.961L195.183 79.1566L179.228 104.779L187.513 105.317L184.612 124.534L201.841 125.319L175.127 142.863L173.78 140.8L194.121 127.441L181.77 126.878L184.681 107.612L174.96 106.98L193.861 76.6298L211.881 77.5033C214.791 77.7106 217.461 79.1418 219.236 81.4366C221.006 83.7314 221.724 86.6875 221.198 89.5399L214.688 127.643L227.324 142.167L233.765 188.882L231.332 189.223L231.327 189.218Z"
          fill="#1D1D1B"
        />
        <path
          d="M184.308 240.231L182.026 239.309L202.515 188.394H266.935L251.683 224.222L249.421 223.255L263.213 190.861H204.172L184.308 240.231Z"
          fill="#1D1D1B"
        />
        <path
          d="M206.554 189.539L186.388 239.686L186.844 239.871L207.009 189.724L206.554 189.539Z"
          fill="#1D1D1B"
        />
        <path
          d="M146.104 239.481H144.138V232.661H185.493V234.635H146.104V239.481Z"
          fill="#1D1D1B"
        />
        <path
          d="M331.389 266.368H240.128L222.899 222.214H269.398L285.663 263.9H327.136L284.684 189.835L292.758 151.504C293.362 148.642 292.654 145.696 290.815 143.421C288.981 141.146 286.253 139.843 283.342 139.843H273.965C266.536 139.843 260.1 145.153 258.659 152.472L254.254 174.892C253.6 178.223 254.456 181.638 256.604 184.263C258.753 186.889 261.924 188.389 265.307 188.389V190.856C261.187 190.856 257.322 189.026 254.701 185.828C252.086 182.63 251.038 178.469 251.835 174.413L256.24 151.993C257.902 143.524 265.356 137.375 273.956 137.375H283.332C286.99 137.375 290.412 139.014 292.718 141.866C295.024 144.719 295.909 148.42 295.152 152.013L287.27 189.42L331.374 266.368H331.389ZM241.805 263.9H283.022L267.721 224.681H226.503L241.805 263.9Z"
          fill="#1D1D1B"
        />
        <path
          d="M265.09 223.344L264.632 223.524L280.9 265.211L281.358 265.031L265.09 223.344Z"
          fill="#1D1D1B"
        />
        <path
          d="M181.2 182.926H178.741V204.788H181.2V182.926Z"
          fill="#1D1D1B"
        />
        <path
          d="M227.015 143.574L225.294 141.812L249.622 117.877C252.474 115.069 253.821 111.027 253.226 107.059L250.679 90.1716C249.853 84.6838 251.447 79.122 255.051 74.9124C258.659 70.7028 263.896 68.2896 269.427 68.2896C278.592 68.2896 286.051 75.776 286.051 84.975H283.593C283.593 77.1381 277.24 70.7571 269.427 70.7571C264.614 70.7571 260.051 72.8594 256.914 76.5212C253.777 80.183 252.386 85.0243 253.108 89.8014L255.655 106.689C256.373 111.437 254.76 116.278 251.343 119.639L227.015 143.574Z"
          fill="#1D1D1B"
        />
        <path
          d="M256.162 89.4017C255.282 85.5819 256.103 81.5796 258.414 78.4212C260.724 75.2628 264.279 73.2739 268.173 72.9729C271.276 72.7311 274.29 73.7181 276.65 75.7513C279.015 77.7846 280.451 80.6173 280.687 83.7313L280.195 83.7708C279.964 80.79 278.592 78.0708 276.326 76.1264C274.059 74.182 271.178 73.2345 268.203 73.4664C264.451 73.7576 261.024 75.6724 258.802 78.7124C256.575 81.7573 255.788 85.6116 256.634 89.2882L256.157 89.4017H256.162Z"
          fill="#1D1D1B"
        />
        <path
          d="M173.921 88.2744L173.834 89.2575L219.433 93.3422L219.521 92.3591L173.921 88.2744Z"
          fill="#1D1D1B"
        />
        <path
          d="M191.5 101.419C191.451 101.419 191.407 101.414 191.363 101.404C190.296 101.167 190.409 97.6827 190.468 96.6216L190.96 96.6513C190.837 98.8375 191.073 100.836 191.471 100.925C191.589 100.95 192.253 100.861 193.974 96.5476L194.431 96.7302C192.838 100.728 192.022 101.423 191.5 101.423V101.419Z"
          fill="#1D1D1B"
        />
        <path
          d="M185.817 109.413L185.748 108.925C185.787 108.92 189.79 108.328 192.612 106.344L192.892 106.748C189.972 108.801 185.984 109.389 185.817 109.413Z"
          fill="#1D1D1B"
        />
        <path
          d="M204.949 111.432C204.487 111.432 204.015 111.308 203.557 111.062L204.019 110.193C204.723 110.573 205.396 110.529 206.075 110.06C207.923 108.786 209.064 104.922 208.779 102.563C208.69 101.818 208.425 100.876 207.682 100.594C206.04 99.9774 203.115 102.81 202.126 103.955L200.922 105.352L203.597 91.5879L204.56 91.7754L202.554 102.095C203.941 100.782 206.247 99.0052 208.027 99.6764C208.981 100.037 209.58 100.994 209.757 102.45C210.087 105.184 208.823 109.369 206.635 110.879C206.094 111.254 205.524 111.442 204.954 111.442L204.949 111.432Z"
          fill="#1D1D1B"
        />
        <path
          d="M207.606 108.809L206.939 109.534L214.49 116.53L215.157 115.805L207.606 108.809Z"
          fill="#1D1D1B"
        />
        <path
          d="M292.077 82.4923L248.363 92.115L248.679 93.5611L292.393 83.9384L292.077 82.4923Z"
          fill="#1D1D1B"
        />
        <path
          d="M270.086 138.313L262.745 128.083C259.918 124.144 259.544 119.022 261.766 114.714C263.989 110.405 268.37 107.765 273.208 107.795L283.99 107.883L286.257 94.2183L284.62 94.3516C283.228 94.4601 281.861 93.9716 280.853 93.0043C279.85 92.037 279.305 90.6799 279.354 89.2833L279.477 85.9867L281.443 86.0607L281.321 89.3573C281.291 90.1913 281.616 91.0007 282.215 91.5781C282.815 92.1555 283.636 92.4466 284.462 92.3825L288.612 92.0518L285.652 109.872L273.188 109.769C269.038 109.739 265.42 111.92 263.512 115.622C261.604 119.323 261.914 123.552 264.338 126.933L271.679 137.163L270.081 138.318L270.086 138.313Z"
          fill="#1D1D1B"
        />
        <path
          d="M271.448 138.53L270.45 137.444L292.959 116.589H333.036V118.069H293.535L271.448 138.53Z"
          fill="#1D1D1B"
        />
        <path
          d="M299.102 121.132L285.346 133.859L285.679 134.222L299.435 121.495L299.102 121.132Z"
          fill="#1D1D1B"
        />
        <path
          d="M305.053 121.128L291.297 133.855L291.63 134.218L305.386 121.491L305.053 121.128Z"
          fill="#1D1D1B"
        />
        <path
          d="M318.851 121.129L305.095 133.856L305.428 134.219L319.184 121.492L318.851 121.129Z"
          fill="#1D1D1B"
        />
        <path
          d="M317.061 151.267H293.962V152.254H317.061V151.267Z"
          fill="#1D1D1B"
        />
        <path
          d="M241.893 123.093C234.385 123.093 228.273 116.964 228.273 109.423C228.273 101.882 234.38 95.7531 241.893 95.7531C249.406 95.7531 255.513 101.882 255.513 109.423C255.513 116.964 249.406 123.093 241.893 123.093ZM241.893 97.7321C235.467 97.7321 230.24 102.978 230.24 109.428C230.24 115.878 235.467 121.124 241.893 121.124C248.319 121.124 253.546 115.878 253.546 109.428C253.546 102.978 248.319 97.7321 241.893 97.7321Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.298 30.1465H248.314V103.027H249.298V30.1465Z"
          fill="#1D1D1B"
        />
        <path
          d="M256.27 30.1465H255.286V80.8739H256.27V30.1465Z"
          fill="#1D1D1B"
        />
        <path
          d="M307.189 40.5298H221.887V31.089H237.975V39.5428H307.189V40.5298ZM222.87 39.5428H236.991V32.076H222.87V39.5428Z"
          fill="#1D1D1B"
        />
        <path
          d="M174.946 141.674H173.962V117.299L155.372 131.29V117.299L136.786 131.29V117.299L118.983 130.698L118.393 129.909L137.77 115.325V129.316L156.355 115.325V129.316L174.946 115.325V141.674Z"
          fill="#1D1D1B"
        />
        <path
          d="M253.231 265.539L249.494 253.458L234.97 250.654L235.343 248.715L251.023 251.74L255.109 264.956L253.231 265.539Z"
          fill="#1D1D1B"
        />
        <path
          d="M285.983 188.394H265.071V189.874H285.983V188.394Z"
          fill="#1D1D1B"
        />
        <path
          d="M288.027 263.964L290.397 238.692L275.74 241.905L275.317 239.975L292.61 236.19L290.333 260.475L314.563 247.358L315.498 249.095L288.027 263.964Z"
          fill="#1D1D1B"
        />
        <path
          d="M154.606 171.627L147.89 182.828L148.732 183.337L155.448 172.136L154.606 171.627Z"
          fill="#1D1D1B"
        />
        <path
          d="M172.723 141.555L159.659 162.986L160.498 163.502L173.562 142.07L172.723 141.555Z"
          fill="#1D1D1B"
        />
        <path
          d="M157.796 174.023C157.097 174.023 156.527 173.776 156.099 173.49C153.774 171.926 152.844 167.257 153.567 164.128C153.975 162.376 154.87 161.32 156.089 161.157C159.92 160.644 161.542 164.637 161.867 166.823L160.893 166.971C160.859 166.754 160.033 161.626 156.217 162.139C155.16 162.283 154.703 163.59 154.526 164.355C153.901 167.06 154.708 171.373 156.645 172.671C157.505 173.248 158.513 173.13 159.644 172.32L160.215 173.125C159.29 173.786 158.479 174.023 157.796 174.023Z"
          fill="#1D1D1B"
        />
        <path
          d="M169.985 163.783C169.562 163.783 168.195 163.324 166.096 158.236L166.548 158.048C167.492 160.328 169.174 163.462 169.98 163.289C170.182 163.245 170.831 162.771 170.722 158.438L171.214 158.423C171.327 162.924 170.668 163.644 170.083 163.768C170.059 163.773 170.029 163.778 169.985 163.778V163.783Z"
          fill="#1D1D1B"
        />
        <path
          d="M182.729 172.676C182.616 172.676 179.961 172.641 178.019 169.651L178.432 169.384C180.216 172.143 182.704 172.187 182.729 172.187V172.681V172.676Z"
          fill="#1D1D1B"
        />
        <path
          d="M180.507 202.528L179.436 201.511L197.765 181.983L198.223 182.151C200.657 183.049 203.39 182.412 205.185 180.537C206.591 179.066 207.211 177.063 206.93 175.089L202.073 180.182L196.251 174.59L201.109 169.497C199.132 169.3 197.166 170.011 195.759 171.481C193.965 173.362 193.453 176.125 194.451 178.533L194.638 178.987L180.345 193.506L179.298 192.465L192.913 178.637C191.934 175.804 192.613 172.641 194.692 170.46C196.782 168.273 199.88 167.454 202.776 168.333L203.926 168.683L198.326 174.551L202.014 178.099L207.614 172.231L208.012 173.371C209.01 176.239 208.332 179.382 206.242 181.569C204.157 183.755 201.04 184.564 198.174 183.705L180.498 202.533L180.507 202.528Z"
          fill="#1D1D1B"
        />
        <path
          d="M205.794 138.609C205.081 138.609 204.378 138.54 203.68 138.397C197.042 137.05 193.743 129.79 193.61 129.484L194.51 129.08C194.54 129.149 197.745 136.186 203.887 137.43C207.933 138.249 212.383 136.418 217.108 131.981L217.781 132.702C213.597 136.625 209.575 138.604 205.799 138.604L205.794 138.609Z"
          fill="#1D1D1B"
        />
        <path
          d="M184.829 162.445L192.907 129.252L194.338 129.608L186.737 160.841L215.658 159.085L217.083 133.013L218.558 133.092L217.059 160.481L184.829 162.445Z"
          fill="#1D1D1B"
        />
        <path
          d="M185.942 133.481L178.678 161.756L180.106 162.126L187.371 133.85L185.942 133.481Z"
          fill="#1D1D1B"
        />
        <path
          d="M223.696 139.196H222.221V188.902H223.696V139.196Z"
          fill="#1D1D1B"
        />
        <path
          d="M265.267 100.476C264.446 100.476 263.846 100.165 263.438 99.8244C261.865 98.5314 261.555 95.6592 262.062 93.6161C262.421 92.1702 263.168 91.2325 264.107 91.045C266.148 90.6354 267.411 92.4219 268.095 93.9221L269.093 87.8076L270.061 87.9655L268.355 98.4179L267.79 96.0146C267.78 95.9702 266.703 91.5286 264.299 92.0122C263.753 92.1208 263.271 92.8117 263.016 93.853C262.583 95.5902 262.863 98.0725 264.058 99.0595C264.731 99.6122 265.636 99.627 266.752 99.099L267.175 99.9922C266.443 100.343 265.808 100.476 265.267 100.476Z"
          fill="#1D1D1B"
        />
        <path
          d="M263.155 98.4666L254.119 106.522L254.772 107.26L263.808 99.2046L263.155 98.4666Z"
          fill="#1D1D1B"
        />
        <path
          d="M233.74 100.593L233.67 100.829L254.405 106.99L254.474 106.754L233.74 100.593Z"
          fill="#1D1D1B"
        />
        <path
          d="M284.631 95.3583L284.144 95.2843L285.393 87.3635L266.743 93.0783L266.601 92.6095L285.997 86.6627L284.631 95.3583Z"
          fill="#1D1D1B"
        />
        <path
          d="M278.16 94.5292C277.295 94.5292 276.779 92.3676 276.597 91.4349L277.079 91.3411C277.354 92.7526 277.87 94.0357 278.16 94.0357C278.175 94.0357 278.652 94.0357 279.212 91.05L279.694 91.1388C279.139 94.1196 278.608 94.5243 278.165 94.5292H278.16Z"
          fill="#1D1D1B"
        />
        <path
          d="M250.821 118.407L250.149 119.129L270.547 138.254L271.218 137.532L250.821 118.407Z"
          fill="#1D1D1B"
        />
        <path
          d="M133.001 240.414C132.475 240.414 131.929 240.38 131.354 240.315L129.771 240.138L143.769 218.498L161.922 228.53L160.417 229.409C160.117 229.582 153.179 233.515 141.246 231.24C141.276 232.997 140.996 236.051 138.96 238.179C137.534 239.669 135.528 240.419 132.996 240.419L133.001 240.414ZM139.088 229.365L133.217 238.44C135.091 238.401 136.541 237.853 137.539 236.816C139.811 234.453 139.211 230.203 139.201 230.159L139.083 229.369L139.088 229.365ZM139.432 228.832L140.391 229.044C146.62 230.426 151.468 229.986 154.433 229.374C155.79 229.093 156.892 228.753 157.708 228.452L144.423 221.113L139.427 228.836L139.432 228.832Z"
          fill="#1D1D1B"
        />
        <path
          d="M160.682 213.701C158.435 213.701 156.07 213.4 153.592 212.793C145.769 210.878 139.53 206.55 139.466 206.505L140.026 205.696C140.085 205.74 146.197 209.975 153.848 211.84C164.006 214.318 171.927 211.653 177.394 203.919L178.196 204.492C173.888 210.587 167.91 213.701 160.682 213.701Z"
          fill="#1D1D1B"
        />
        <path
          d="M236.773 254.818L236.725 255.309L251.394 256.766L251.442 256.274L236.773 254.818Z"
          fill="#1D1D1B"
        />
        <path
          d="M252.261 260.087L239.047 261.256L239.09 261.748L252.304 260.578L252.261 260.087Z"
          fill="#1D1D1B"
        />
        <path
          d="M257.357 230.791H238.732L236.146 223.615L237.07 223.28L239.425 229.804H255.946L253.689 223.709L254.608 223.364L257.357 230.791Z"
          fill="#1D1D1B"
        />
        <path
          d="M265.165 222.031V189.776H266.148V218.744L285.653 189.075L286.475 189.618L265.165 222.031Z"
          fill="#1D1D1B"
        />
        <path
          d="M154.453 230.668C149.87 228.536 141.153 224.489 140.951 224.4L141.04 224.188L140.936 224.395L140.818 224.064C140.868 223.961 140.897 223.907 141.138 223.985L141.153 223.956L141.138 223.985C141.423 224.074 142.003 224.346 143.257 224.923L154.654 230.218L154.448 230.668H154.453Z"
          fill="#1D1D1B"
        />
        <path
          d="M156.581 230.199C144.614 220.501 144.658 220.383 144.737 220.161L144.973 219.993V220.24L145.174 220.116C145.494 220.526 152.353 226.142 156.891 229.814L156.581 230.199Z"
          fill="#1D1D1B"
        />
        <path
          d="M268.636 146.673C266.704 146.673 265.986 146.145 265.662 145.573C264.437 143.401 269.172 139.325 270.136 138.525L270.76 139.285C268.695 140.998 265.917 144.023 266.517 145.084C266.97 145.888 270.043 147.033 288.727 140.336L289.056 141.264C277.413 145.439 271.596 146.673 268.636 146.673Z"
          fill="#1D1D1B"
        />
        <path
          d="M264.559 170.297C263.65 170.297 262.838 170.134 262.14 169.808C260.522 169.058 260.114 167.701 260.1 167.642L261.044 167.361C261.058 167.4 261.363 168.367 262.583 168.92C263.713 169.433 265.892 169.68 269.81 167.923L270.214 168.826C268.035 169.803 266.142 170.292 264.564 170.292L264.559 170.297Z"
          fill="#1D1D1B"
        />
        <path
          d="M268.415 177.68C264.299 177.68 259.82 177.122 259.505 177.083L259.235 177.048L259.186 176.782C259.176 176.718 258.915 175.193 260.145 174.265C261.979 172.888 265.986 173.722 272.053 176.747C273.13 176.599 273.976 176.362 274.418 175.992L274.885 176.565C273.848 177.423 271.212 177.68 268.41 177.68H268.415ZM259.899 176.382C262.352 176.678 267.161 177.093 270.642 176.875C263.886 173.678 261.452 174.206 260.597 174.847C259.977 175.306 259.894 176.002 259.899 176.382Z"
          fill="#1D1D1B"
        />
        <path
          d="M264.978 181.771C264.644 181.771 264.314 181.736 263.99 181.672C261.777 181.223 260.725 179.392 260.681 179.313L261.32 178.948C261.32 178.948 262.264 180.572 264.142 180.947C265.72 181.268 267.52 180.651 269.487 179.131L269.939 179.718C268.174 181.085 266.507 181.771 264.973 181.771H264.978Z"
          fill="#1D1D1B"
        />
        <path
          d="M275.086 163.882C275.027 163.882 274.968 163.877 274.909 163.867C273.183 163.576 272.844 158.774 272.814 158.231L273.306 158.201C273.419 160.116 273.98 163.21 274.993 163.383C275.479 163.467 276.581 162.909 278.498 158.27L278.951 158.458C277.426 162.149 276.192 163.886 275.086 163.886V163.882Z"
          fill="#1D1D1B"
        />
        <path
          d="M259.26 162.421C259.186 162.421 259.112 162.411 259.039 162.396C257.47 162.031 257.421 157.555 257.421 157.046H257.913C257.913 158.818 258.247 161.705 259.147 161.917C259.555 162.016 260.518 161.606 262.382 157.811L262.824 158.029C261.359 161.009 260.219 162.421 259.26 162.421Z"
          fill="#1D1D1B"
        />
        <path
          d="M272.29 189.479L265.315 197.784L265.691 198.102L272.666 189.797L272.29 189.479Z"
          fill="#1D1D1B"
        />
        <path
          d="M274.882 189.354L274.445 189.58L279.529 199.492L279.966 199.266L274.882 189.354Z"
          fill="#1D1D1B"
        />
        <path
          d="M268.717 193.766L268.708 194.26L276.987 194.406L276.995 193.913L268.717 193.766Z"
          fill="#1D1D1B"
        />
        <path
          d="M290.515 167.025C286.936 161.745 285.913 156.706 287.482 152.042C289.099 147.226 292.88 144.679 293.234 144.561L293.392 145.03L293.583 145.484C293.067 145.74 289.379 148.524 288.165 153.207C287.093 157.333 288.16 161.794 291.331 166.472L290.52 167.03L290.515 167.025Z"
          fill="#1D1D1B"
        />
        <path
          d="M288.598 228.457C285.004 228.457 282.078 225.52 282.078 221.913C282.078 218.305 285.004 215.369 288.598 215.369C292.192 215.369 295.118 218.305 295.118 221.913C295.118 225.52 292.192 228.457 288.598 228.457ZM288.598 216.361C285.544 216.361 283.061 218.853 283.061 221.918C283.061 224.982 285.544 227.475 288.598 227.475C291.651 227.475 294.134 224.982 294.134 221.918C294.134 218.853 291.651 216.361 288.598 216.361Z"
          fill="#1D1D1B"
        />
        <path
          d="M288.598 224.953C286.926 224.953 285.569 223.591 285.569 221.913C285.569 220.235 286.926 218.873 288.598 218.873C290.27 218.873 291.627 220.235 291.627 221.913C291.627 223.591 290.27 224.953 288.598 224.953ZM288.598 219.371C287.202 219.371 286.061 220.511 286.061 221.918C286.061 223.324 287.197 224.464 288.598 224.464C289.999 224.464 291.135 223.324 291.135 221.918C291.135 220.511 289.999 219.371 288.598 219.371Z"
          fill="#1D1D1B"
        />
        <path
          d="M289.335 194.114H287.86V215.867H289.335V194.114Z"
          fill="#1D1D1B"
        />
        <path
          d="M284.655 98.7508L284.402 99.174L285.853 100.047L286.106 99.6242L284.655 98.7508Z"
          fill="#1D1D1B"
        />
        <path
          d="M270.804 193.921L265.135 208.784L265.594 208.961L271.264 194.097L270.804 193.921Z"
          fill="#1D1D1B"
        />
        <path
          d="M274.713 193.841L273.261 209.144L273.75 209.191L275.202 193.888L274.713 193.841Z"
          fill="#1D1D1B"
        />
        <path
          d="M291.504 238.425C291.057 238.425 290.649 238.114 290.545 237.656C290.427 237.123 290.757 236.594 291.288 236.476L302.179 233.999C302.705 233.88 303.236 234.211 303.354 234.744C303.472 235.277 303.142 235.805 302.611 235.923L291.721 238.401C291.647 238.416 291.573 238.425 291.504 238.425Z"
          fill="#1D1D1B"
        />
        <path
          d="M304.942 254.135L304.477 254.295L308.106 264.936L308.571 264.776L304.942 254.135Z"
          fill="#1D1D1B"
        />
        <path
          d="M230.84 173.974L230.176 172.113C230.211 172.103 233.544 170.889 239.508 167.899C239.612 167.844 239.715 167.79 239.813 167.736L227.703 157.737L228.952 156.212L238.638 164.212L237.586 150.883L256.585 148.208L256.132 149.688C256.098 149.792 252.956 159.766 243.693 161.044C244.16 162.139 244.592 163.743 244.174 165.416C243.732 167.198 242.454 168.629 240.379 169.67C234.297 172.725 230.968 173.929 230.83 173.979L230.84 173.974ZM239.695 152.58L240.836 167.06C241.588 166.448 242.07 165.742 242.272 164.947C242.793 162.89 241.337 160.777 241.323 160.753L240.275 159.252L242.1 159.198C245.866 159.085 249.042 157.382 251.55 154.13C252.538 152.847 253.226 151.559 253.659 150.611L239.695 152.58Z"
          fill="#1D1D1B"
        />
        <path
          d="M247.494 138.88L227.454 153.895L228.336 155.081L248.376 140.067L247.494 138.88Z"
          fill="#1D1D1B"
        />
        <path
          d="M314.598 131.226H284.389V131.72H314.598V131.226Z"
          fill="#1D1D1B"
        />
        <path
          d="M305.3 123.498H292.374V123.992H305.3V123.498Z"
          fill="#1D1D1B"
        />
        <path
          d="M132.292 150.374H126.574V137.198H132.292V150.374ZM127.066 149.881H131.801V137.691H127.066V149.881Z"
          fill="#1D1D1B"
        />
        <path
          d="M144.781 145.173H144.289V137.203H150.012V142.113H149.521V137.696H144.781V145.173Z"
          fill="#1D1D1B"
        />
        <path
          d="M167.728 141.235H167.236V137.696H162.501V140.8H162.01V137.203H167.728V141.235Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.127 29.8461L248.779 30.195L255.389 36.8288L255.736 36.4798L249.127 29.8461Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.389 29.8426L248.779 36.4763L249.127 36.8253L255.736 30.1916L255.389 29.8426Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.129 36.6983L248.781 37.0472L255.39 43.681L255.738 43.332L249.129 36.6983Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.394 36.6933L248.785 43.327L249.133 43.676L255.742 37.0423L255.394 36.6933Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.126 43.5455L248.778 43.8945L255.388 50.5283L255.735 50.1793L249.126 43.5455Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.391 43.5455L248.782 50.1793L249.13 50.5282L255.739 43.8945L255.391 43.5455Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.127 50.4012L248.779 50.7502L255.389 57.3839L255.736 57.035L249.127 50.4012Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.389 50.3978L248.779 57.0315L249.127 57.3805L255.736 50.7467L255.389 50.3978Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.13 57.2484L248.782 57.5973L255.391 64.2311L255.739 63.8821L249.13 57.2484Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.391 57.2449L248.782 63.8786L249.13 64.2276L255.739 57.5939L255.391 57.2449Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.127 64.1006L248.779 64.4496L255.389 71.0833L255.736 70.7343L249.127 64.1006Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.389 64.097L248.779 70.7308L249.127 71.0797L255.736 64.446L255.389 64.097Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.136 70.9587L248.78 71.299L254.589 77.421L254.945 77.0807L249.136 70.9587Z"
          fill="#1D1D1B"
        />
        <path
          d="M255.389 70.9479L248.78 77.5816L249.128 77.9305L255.737 71.2968L255.389 70.9479Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.13 77.8046L248.769 78.1393L252.254 81.9288L252.615 81.594L249.13 77.8046Z"
          fill="#1D1D1B"
        />
        <path
          d="M252.703 80.7037L248.783 84.422L249.121 84.7807L253.041 81.0624L252.703 80.7037Z"
          fill="#1D1D1B"
        />
        <path
          d="M249.119 84.6437L248.781 85.002L251.54 87.6255L251.879 87.2672L249.119 84.6437Z"
          fill="#1D1D1B"
        />
        <path
          d="M251.539 88.7124L248.78 91.2646L249.114 91.6274L251.872 89.0753L251.539 88.7124Z"
          fill="#1D1D1B"
        />
        <path
          d="M284.577 81.7972L251.791 89.0022L252.001 89.9664L284.787 82.7614L284.577 81.7972Z"
          fill="#1D1D1B"
        />
        <path
          d="M195.208 48.6333H169.66L167.423 44.6408H170.811L172.536 47.4587C173.471 47.286 178.181 46.4323 183.039 45.8894C190.478 45.0652 194.544 45.3367 195.468 46.7185C195.798 47.212 195.734 47.819 195.277 48.5198L195.203 48.6333H195.208ZM169.95 48.1398H194.937C195.218 47.666 195.257 47.291 195.06 46.9948C193.443 44.5668 178.201 46.8764 172.463 47.972L172.295 48.0016L170.535 45.1294H168.259L169.945 48.1348L169.95 48.1398Z"
          fill="#F78757"
        />
        <path
          d="M169.802 48.1397H122.97V48.6332H169.802V48.1397Z"
          fill="#F78757"
        />
        <path
          d="M170.016 141.448L140.947 175.536L141.134 175.697L170.202 141.608L170.016 141.448Z"
          fill="#1D1D1B"
        />
        <path
          d="M159.832 140.476L134.301 163.606L134.466 163.79L159.996 140.659L159.832 140.476Z"
          fill="#1D1D1B"
        />
        <path
          d="M174.211 183.763L173.713 203.506L173.959 203.513L174.457 183.769L174.211 183.763Z"
          fill="#1D1D1B"
        />
        <path
          d="M170.266 185.526L169.386 199.227L169.631 199.243L170.511 185.542L170.266 185.526Z"
          fill="#1D1D1B"
        />
        <path
          d="M203.859 93.8988L203.719 94.1014L217.079 103.429L217.22 103.226L203.859 93.8988Z"
          fill="#1D1D1B"
        />
        <path
          d="M209.66 92.1485L209.531 92.3589L218.536 97.8976L218.665 97.6872L209.66 92.1485Z"
          fill="#1D1D1B"
        />
        <path
          d="M208.808 104.993L208.651 105.183L215.331 110.721L215.487 110.531L208.808 104.993Z"
          fill="#1D1D1B"
        />
        <path
          d="M229.268 109.3L229.239 109.546L254.019 112.494L254.048 112.249L229.268 109.3Z"
          fill="#1D1D1B"
        />
        <path
          d="M253.162 116.034L232.54 117.209L232.554 117.456L253.176 116.28L253.162 116.034Z"
          fill="#1D1D1B"
        />
        <path
          d="M259.476 90.4107L253.667 95.0752L253.821 95.2679L259.63 90.6034L259.476 90.4107Z"
          fill="#1D1D1B"
        />
        <path
          d="M262.684 94.2109L254.256 100.325L254.4 100.525L262.828 94.4109L262.684 94.2109Z"
          fill="#1D1D1B"
        />
        <path
          d="M252.825 153.528L239.465 156.157L239.559 156.641L252.92 154.012L252.825 153.528Z"
          fill="#1D1D1B"
        />
        <path
          d="M253.735 151.778L239.503 152.364L239.523 152.857L253.755 152.271L253.735 151.778Z"
          fill="#1D1D1B"
        />
        <path
          d="M225.22 219.218C224.482 219.218 223.833 218.892 223.342 218.275C222.378 217.071 222.103 214.865 222.614 212.506C223.416 208.835 225.874 205.849 228.091 205.849C228.829 205.849 229.478 206.175 229.97 206.792C230.933 207.996 231.209 210.202 230.697 212.561C229.896 216.232 227.438 219.218 225.22 219.218ZM228.091 206.101C225.982 206.101 223.637 208.998 222.855 212.566C222.354 214.85 222.614 216.977 223.534 218.122C223.981 218.68 224.561 218.971 225.22 218.971C227.329 218.971 229.675 216.074 230.456 212.511C230.958 210.226 230.697 208.099 229.778 206.949C229.33 206.392 228.75 206.101 228.091 206.101Z"
          fill="#1D1D1B"
        />
        <path
          d="M243.885 223.892C241.126 223.892 238.279 223.063 237.753 221.321C237.581 220.753 237.68 220.171 238.039 219.638C238.82 218.483 240.856 217.644 243.349 217.442C246.402 217.195 249.962 217.999 250.562 219.968C250.734 220.536 250.635 221.118 250.276 221.651C249.495 222.806 247.459 223.645 244.966 223.847C244.612 223.877 244.248 223.892 243.885 223.892ZM244.475 218.878C244.15 218.878 243.816 218.893 243.472 218.917C241.175 219.1 239.681 219.855 239.263 220.472C239.125 220.674 239.14 220.798 239.169 220.886C239.405 221.661 241.736 222.619 244.853 222.372C247.149 222.189 248.644 221.434 249.062 220.817C249.2 220.615 249.185 220.492 249.155 220.403C248.949 219.717 247.081 218.878 244.475 218.878Z"
          fill="#1D1D1B"
        />
        <path
          d="M297.826 257.448L297.383 257.663L301.158 265.533L301.601 265.319L297.826 257.448Z"
          fill="#1D1D1B"
        />
        <path
          d="M317.061 155.393H293.962V156.874H317.061V155.393Z"
          fill="#1D1D1B"
        />
        <path
          d="M305.92 34.9533H305.551V54.195H305.92V34.9533Z"
          fill="#1D1D1B"
        />
        <path
          d="M146.093 303.672C144.747 311.211 139.686 314.782 133.518 314.782C127.984 314.782 124.742 312.003 123.952 307.639L123.712 307.562C122.451 311.685 118.806 314.782 113.195 314.782C106.632 314.782 102.833 309.86 102.833 304.549C102.833 297.328 108.846 292.413 118.256 290.426L123.473 289.316V287.965C123.473 282.965 121.732 278.601 115.169 278.601C110.263 278.601 106.943 280.504 104.891 286.536L104.334 286.381C105.681 276.699 112.004 270.751 122.126 270.751C133.75 270.751 138.494 278.29 138.494 288.92V302.88C138.494 305.659 139.918 306.847 141.497 306.847C142.759 306.847 144.585 306.211 145.29 303.594L146.079 303.672H146.093ZM123.48 305.97V291.692L122.373 291.932C117.869 292.802 116.36 296.14 116.36 300.341C116.36 304.542 118.334 306.847 121.266 306.847C122.296 306.847 123.008 306.607 123.48 305.977V305.97Z"
          fill="#2B2B2B"
        />
        <path
          d="M94.036 277.413L90.2295 314.103H28.2607V313.339C34.3582 311.812 37.0228 309.393 37.0228 300.348V237.922C37.0228 229.641 34.4851 227.222 28.2607 225.695V224.931H66.6079V225.695C60.3835 227.222 57.8459 231.048 57.8459 237.922V310.921H61.5255C78.9227 310.921 87.8116 299.075 93.1407 277.42H94.0289L94.036 277.413Z"
          fill="#2B2B2B"
        />
        <path
          d="M95.0498 420.34C95.0498 392.441 113.716 373.205 141.017 373.205C168.318 373.205 187.111 392.441 187.111 420.34C187.111 448.24 168.318 467.476 141.017 467.476C113.716 467.476 95.0498 448.24 95.0498 420.34ZM163.617 420.34C163.617 391.168 156.377 377.667 141.017 377.667C125.657 377.667 118.418 391.168 118.418 420.34C118.418 449.513 125.911 463.013 141.017 463.013C156.123 463.013 163.617 449.386 163.617 420.34Z"
          fill="#2B2B2B"
        />
        <path
          d="M96.8173 361.253V375.616H96.4225C95.3934 374.98 94.2091 374.662 91.756 374.662C86.6948 374.662 83.2971 377.045 83.2971 387.278V397.752C83.2971 401.642 85.8277 403.07 88.7531 403.7V404.096H64.3984V403.7C66.6119 402.745 68.2754 401.005 68.2754 397.752V374.428C68.2754 370.143 67.0912 368 64.3984 367.13V366.734L82.6627 361.26V380.779H83.135C83.6919 366.5 88.8307 361.26 95.633 361.26H96.8173V361.253Z"
          fill="#2B2B2B"
        />
        <path
          d="M104.495 329.986C102.916 326.493 101.492 324.831 99.2783 324.116V323.72H123.95V324.116C121.575 324.675 120.468 325.786 120.468 327.688C120.468 328.558 120.785 329.59 121.257 330.785L127.58 345.778L133.904 330.785C134.376 329.597 134.693 328.565 134.693 327.773C134.693 325.87 133.586 324.675 131.211 324.124V323.728H142.285V324.124C139.994 324.76 138.648 326.422 137.069 329.993L121.095 366.174H120.623L104.488 329.993L104.495 329.986Z"
          fill="#2B2B2B"
        />
        <path
          d="M140.79 344.505C140.79 331.415 149.883 322.532 162.459 322.532C175.035 322.532 184.128 331.415 184.128 344.505C184.128 357.596 175.035 366.563 162.459 366.563C149.883 366.563 140.79 357.596 140.79 344.505ZM168.31 344.505C168.31 330.623 166.491 325.623 162.459 325.623C158.427 325.623 156.608 330.623 156.608 344.505C156.608 358.388 158.427 363.388 162.459 363.388C166.491 363.388 168.31 358.388 168.31 344.505Z"
          fill="#2B2B2B"
        />
        <!-- <g clip-path="url(#clip1_2192_937)" class="card-tema__arrow">
          <rect width="66" height="66" x="285" y="429" rx="15" fill="white" />
          <rect
            width="58"
            height="58"
            x="293"
            y="437"
            rx="15"
            fill="white"
            filter="url(#blurFilter)"
          />
          <path
            d="M286.459 479.605L338.262 428.405L296.267 427.752C291.297 427.674 287.206 431.641 287.128 436.611L286.459 479.605Z"
            fill="#2B2B2B"
          />
          <path
            d="M287.398 436.615L286.738 478.95L337.617 428.665L296.263 428.021C291.442 427.946 287.473 431.793 287.398 436.615Z"
            stroke="#2B2B2B"
            stroke-width="0.538557"
          />
          <path
            d="M312.433 465.405C312.438 465.028 312.763 464.727 313.158 464.733L330.305 465C330.7 465.006 331.015 465.317 331.009 465.695C331.003 466.072 330.679 466.373 330.284 466.367L313.136 466.1C312.742 466.094 312.427 465.783 312.433 465.405Z"
            fill="#2B2B2B"
          />
          <path
            d="M324.166 459.634C324.449 459.372 324.902 459.379 325.177 459.65L330.807 465.208C331.082 465.479 331.075 465.912 330.792 466.175L324.991 471.555C324.708 471.818 324.256 471.811 323.981 471.539C323.706 471.268 323.713 470.835 323.996 470.572L329.284 465.668L324.151 460.601C323.876 460.33 323.883 459.897 324.166 459.634Z"
            fill="#2B2B2B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M324.938 459.871C324.791 459.725 324.549 459.722 324.397 459.862C324.245 460.003 324.242 460.235 324.389 460.38L328.722 464.658L329.805 464.675L324.938 459.871ZM331.288 465.37C331.24 465.231 331.159 465.1 331.045 464.987L325.414 459.429C325.012 459.032 324.349 459.022 323.935 459.406C323.52 459.791 323.51 460.425 323.913 460.822L327.784 464.643L313.162 464.416C312.584 464.407 312.109 464.847 312.1 465.4C312.092 465.953 312.553 466.409 313.131 466.418L327.753 466.645L323.764 470.344C323.35 470.729 323.34 471.363 323.742 471.76C324.145 472.157 324.807 472.168 325.222 471.783L331.023 466.403C331.14 466.294 331.225 466.166 331.277 466.028C331.305 465.955 331.324 465.878 331.334 465.799C331.342 465.733 331.343 465.667 331.337 465.601C331.33 465.521 331.313 465.444 331.288 465.37ZM329.774 466.677L328.691 466.66L324.227 470.8C324.075 470.941 324.071 471.173 324.219 471.318C324.366 471.464 324.608 471.468 324.76 471.327L329.774 466.677ZM329.364 466.035L330.288 466.05C330.5 466.053 330.674 465.892 330.677 465.69C330.68 465.487 330.511 465.321 330.3 465.317L329.376 465.303L329.522 465.447C329.583 465.508 329.617 465.589 329.616 465.673C329.614 465.757 329.578 465.837 329.515 465.896L329.364 466.035ZM328.438 465.288L313.152 465.05C312.941 465.047 312.767 465.208 312.764 465.411C312.761 465.613 312.93 465.78 313.141 465.783L328.426 466.021L328.815 465.661L328.438 465.288Z"
            fill="#2B2B2B"
          />
        </g> -->
      </g>
    </g>
    <defs>
      <filter id="blurFilter">
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
      </filter>
      <filter
        id="filter0_dd_2192_937"
        x="0"
        y="0"
        width="350.876"
        height="494.006"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.508333 0 0 0 0 0.508333 0 0 0 0 0.508333 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2192_937"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.3875 0 0 0 0 0.3875 0 0 0 0 0.3875 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2192_937"
          result="effect2_dropShadow_2192_937"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2192_937"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2192_937">
        <rect
          x="12"
          y="12"
          width="326.876"
          height="470.006"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_2192_937">
        <path
          d="M338.262 428.405L287.268 427.612L286.459 479.605L327.454 480.243C332.976 480.329 337.522 475.922 337.608 470.4L338.262 428.405Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: "tema_lavoro" };
</script>

<style lang="scss" scoped>
.card-tema {
  overflow: visible;

  &__arrow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__background {
    transition: fill 0.3s ease;
  }
  &:hover {
    .card-tema__arrow {
      opacity: 1;
    }

    .card-tema__background {
      fill: var(--background-card-color);
    }
  }
}
</style>
