import { render, staticRenderFns } from "./tema_minoranze.vue?vue&type=template&id=6bfd440d&scoped=true&"
import script from "./tema_minoranze.vue?vue&type=script&lang=js&"
export * from "./tema_minoranze.vue?vue&type=script&lang=js&"
import style0 from "./tema_minoranze.vue?vue&type=style&index=0&id=6bfd440d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bfd440d",
  null
  
)

export default component.exports